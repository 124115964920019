import { useState, useEffect } from 'react';
import { baseApi } from '../common/config';
import { post } from '../common/utils/http';

function getInitialState() {
  return {
    name: '',
    code: '',
    config: {
      color: '#000000',
      salt: false,
      notes: true,
      containers: false,
      allowcontainercombine: true,
      yieldgoal: 50,
      materials: []
    }
  };
}

function useClient() {
  const [client, setClient] = useState(getInitialState());

  function onSave(mdl) {
    return post(`${baseApi}/clients`, mdl)
      .then((data) => {
        setClient(data);
      });
  }

  return {
    client,
    setClient,
    onSave
  };
}

export default useClient;
