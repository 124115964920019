export const stringSort = function (key) {
  return function (a, b) {
    let ka = a[key].toLowerCase(),
      kb = b[key].toLowerCase();

    if (ka < kb) {
      return -1;
    }
    if (ka > kb) {
      return 1;
    }
    return 0;
  };
};
