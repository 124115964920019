import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { IconLock, IconSettings } from '../icons/icons.js';

function User(props) {
  const { user, onLogout } = props;

  const initials = user.name
    .split(' ')
    .map((n) => (n.length > 0 ? n[0] : ''))
    .join('');

  return (
    <div className="flex mb-20">
      <div className="initials-avatar mr-2 ">{initials}</div>
      <p className="mt-2 text-light flex-1">{user.name}</p>
      <div>
        <Link to="/user" className="inline-block mt-2 mr-4 text-center w-4 h-4 align-top">
          <IconSettings className="h-5 w-5" />
        </Link>
        <button
          onClick={onLogout}
          type="button"
          className="inline-block mt-2 mr-4 text-center w-4 h-4 align-top"
        >
          <IconLock className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
}

User.propTypes = {
  user: PropTypes.object,
  onLogout: PropTypes.func,
};

export default User;
