import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconCheck } from '../components/icons/icons.js';

function MemberPassword({ updateMemberPassword }) {
  const [isSaved, setIsSaved] = useState(false);
  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  function onUpdateMemberPassword() {
    setIsSaved(false);

    if (password.length === 0) {
      setErrors(['"password" is not allowed to be empty']);
    } else if (password !== password2) {
      setErrors(['passwords do not match']);
    } else {
      updateMemberPassword(password)
        .then(() => {
          setPassword('');
          setPassword2('');
          setIsSaved(true);
          setErrors([]);
        })
        .catch((ex) => {
          setErrors(ex.details || [err.message]);
        });
    }
  }

  return (
    <div className="flex flex-col max-w-3xl p-4 mb-4">
      <h2 className="text-xl mb-4">Change password</h2>

      <div className="mb-2">
        <label className="block text-dark" htmlFor="create-password">
          Password
        </label>
        <input
          className="input w-full"
          id="create-password"
          type="password"
          name="password"
          onChange={(e) => { setPassword(e.target.value) }}
          value={password}
        />
      </div>
      <div className="mb-8">
        <label className="block text-dark" htmlFor="create-password2">
          Repeat password
        </label>
        <input
          className="input w-full"
          id="create-password2"
          type="password"
          name="password2"
          onChange={(e) => { setPassword2(e.target.value) }}
          value={password2}
        />
      </div>

      <div className="flex justify-end">
        <Link className="button mr-4" to="/members">
          {isSaved ? 'Back' : 'Cancel'}
        </Link>
        <button className="button button-primary" onClick={onUpdateMemberPassword}>
          {isSaved ? <IconCheck className="w-6 h-6 text-white" /> : 'Save'}
        </button>
      </div>

      {errors && (
        <ul>
          {errors.map((err) => (
            <li>{err}</li>
          ))}
        </ul>
      )}
    </div>
  );
}
MemberPassword.propTypes = {
  memberId: PropTypes.string,
  updateMemberPassword: PropTypes.func,
};

export default MemberPassword;
