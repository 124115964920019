import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import cx from 'classnames';
import { REPORT_TIMESPAN } from '../reports/enums.js';

import 'react-day-picker/dist/style.css';

function MultiDatePicker(params) {
  const { className = '', from, to, setFrom, setTo, setTimespan, xpos = 'left' } = params;
  const [tempTo, setTempTo] = useState(to);
  const [tempFrom, setTempFrom] = useState(from);
  const [isOpen, setIsOpen] = useState(false);

  function confirmSelector() {
    setIsOpen(false);
    setFrom(tempFrom);
    setTo(tempTo);
    if (setTimespan) {
      setTimespan(REPORT_TIMESPAN.CUSTOM);
    }
  }

  const popdownClasses = cx('absolute top-full bg-bglight p-6 border-primary border rounded-lg', {
    '-right-16': xpos === 'right',
    'left-0': xpos !== 'right'
  });

  return (
    <div className="relative inline">
      <span className={cx(className, 'cursor-pointer')} onClick={() => setIsOpen(!isOpen)}>
        &#128197;
      </span>
      {isOpen && (
        <div className={popdownClasses}>
          <div className="flex">
            <div className="pr-6 border-r border-primary">
              <p className="text-xl m-0 pb-6">From</p>
              <DayPicker
                mode="single"
                selected={tempFrom}
                onSelect={(day) => setTempFrom(day)}
              />
            </div>
            <div className="pl-6">
              <p className="text-xl m-0 pb-6">To</p>
              <DayPicker
                mode="single"
                selected={tempTo}
                onSelect={(day) => setTempTo(day)}
              />
            </div>
          </div>
          <div className="text-center pt-6">
            <button onClick={confirmSelector} className="button button-primary">
              Confirm
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MultiDatePicker;
