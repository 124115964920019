import React from 'react';

export default function IconGarbage({ className = '' } = {}) {
  return (
    <svg className={className} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path d="M52,12H42V10a6.0066,6.0066,0,0,0-6-6H28a6.0066,6.0066,0,0,0-6,6v2H12a6.0066,6.0066,0,0,0-6,6v6a2,2,0,0,0,2,2h2V54a6.0066,6.0066,0,0,0,6,6H48a6.0066,6.0066,0,0,0,6-6V26h2a2,2,0,0,0,2-2V18A6.0066,6.0066,0,0,0,52,12ZM26,10a2.002,2.002,0,0,1,2-2h8a2.002,2.002,0,0,1,2,2v2H26ZM50,54a2.002,2.002,0,0,1-2,2H16a2.002,2.002,0,0,1-2-2V26H50Zm4-32H10V18a2.002,2.002,0,0,1,2-2H52a2.002,2.002,0,0,1,2,2Z" />
      <path d="M22,52a2,2,0,0,0,2-2V32a2,2,0,0,0-4,0V50A2,2,0,0,0,22,52Z" />
      <path d="M32,52a2,2,0,0,0,2-2V32a2,2,0,0,0-4,0V50A2,2,0,0,0,32,52Z" />
      <path d="M42,52a2,2,0,0,0,2-2V32a2,2,0,0,0-4,0V50A2,2,0,0,0,42,52Z" />
    </svg>
  );
}
