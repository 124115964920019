import React from 'react';
import { useNavigate } from 'react-router-dom';

import ClientEdit from './client-edit.js';
import useClient from './use-client.js';

const ClientCreate = function () {
  const { client, setClient, onSave } = useClient();
  const navigate = useNavigate();

  function onCancel() {
    navigate('/clients');
  }

  function onSavePressed(mdl) {
    onSave(mdl)
      .then(() => {
        navigate('/clients');
      })
      .catch((ex) => {
        console.log(ex);
      });
  }

  return (
    <div className="flex-1 p-12 overflow-y-auto">
      <div className="clients-wrapper flex flex-col max-w-3xl">
        <ClientEdit client={client} onSave={onSavePressed} toggleEditMode={onCancel} />
      </div>
    </div>
  );
};

export default ClientCreate;
