import { useEffect, useState } from 'react';
import { baseApi } from '../common/config';
import { get } from '../common/utils/http';
import { toSimpleDate } from '../common/utils/date.js';

function useReceived({ from, to }){
  const [received, setReceived] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const limit = 15;
  const [lastPage, setLastPage] = useState(false);
  
  useEffect(() => {
    get(`${baseApi}/receive?limit=${limit}&skip=${pageNumber * limit}&from=${toSimpleDate(from)}&to=${toSimpleDate(to)}`)
      .then((data) => {
        setReceived(data);
        setLastPage(data.length < limit);
      });
  }, [pageNumber, from, to]);


  function getPrevPage() {
    setPageNumber(Math.max(pageNumber - 1, 0));
  }

  function getNextPage() {
    setPageNumber(pageNumber + 1);
  }

  return {
    received,
    pageNumber,
    lastPage,
    getPrevPage,
    getNextPage
  }
}

export default useReceived;