import React from 'react';
import PropTypes from 'prop-types';
import { IconRemove } from '../icons/icons.js';

function InputField(props) {
  const {
    label,
    id,
    type = 'text',
    name,
    onChange,
    onRemove,
    value,
    pattern,
    dataValueType,
    required,
    className = '',
    disabled = false,
  } = props;

  return (
    <div className={`mb-2 relative ${className}`}>
      {label && (
        <label className="block text-dark text-sm mb-2" htmlFor={`input-${name}`}>
          {label}
        </label>
      )}
      <input
        className="input w-full"
        id={`input-${name}`}
        type={type}
        name={name}
        pattern={pattern}
        data-value-type={dataValueType}
        onChange={onChange}
        disabled={disabled}
        value={value}
        required={required}
      />
      {onRemove && (
        <button
          className="absolute border rounded mt-2 mr-2 right-0 top-0 w-6 h-6"
          onClick={onRemove}
          disabled={disabled}
        >
          <IconRemove className="w-6 h-6" />
        </button>
      )}
    </div>
  );
}
InputField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRemove: PropTypes.func,
  pattern: PropTypes.string,
  dataValueType: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InputField;
