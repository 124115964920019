import React from 'react';

export default function IconEdit({ className = '' } = {}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        d="M22.8007,1.197a4.0981,4.0981,0,0,0-5.789,0L1.5072,16.7011a1.0135,1.0135,0,0,0-.27.4955L.0227,22.788A1,1,0,0,0,1,24a1.0583,1.0583,0,0,0,.2125-.0225l5.59-1.216a.9963.9963,0,0,0,.494-.2705L22.8007,6.9863a4.0925,4.0925,0,0,0,0-5.7893ZM2.3062,21.692,2.8017,19.41l1.7855,1.786Zm19.0805-16.12L6.5887,20.3691l-2.961-2.961L18.4257,2.6109a2.1,2.1,0,0,1,2.961,0,2.0937,2.0937,0,0,1,0,2.9615Z"
      />
    </svg>
  );
}
