import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { exclude } from '../common/utils/obj.js';

import { clientsState } from '../state/clients.js';
import Client from './client.js';
import { put } from '../common/utils/http.js';
import { baseApi } from '../common/config.js';

const Clients = function () {
  const [clients, setClients] = useRecoilState(clientsState)
  
  function updateClient(mdl){
    const newMdl = exclude(mdl, ['_id', 'companyId']);
    return put(`${baseApi}/clients/${mdl._id}`, newMdl)
      .then((data) => {
        const updatedClients = clients.map((cl) => cl._id === data._id ? data : cl);
        setClients(updatedClients);
        return data;
      });
  }
  
  return (
    <div className="flex-1 p-12 overflow-y-auto">
      <div className="clients-wrapper flex flex-col max-w-3xl">
        <div className="mb-4">
          <Link to="/clients/create" className="button text-bgdark border-bgdark text-sm">+ Create new client</Link>
        </div>
        {clients.map((client) => (
          <Client key={client._id} client={client} updateClient={updateClient} />
        ))}
      </div>
    </div>
  );
};

export default Clients;
