import React from 'react';

export default function IconHide({ className = '' } = {}) {
  return (
    <svg className={className} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M8,21a1,1,0,0,1-.71-.29l-4-4a1,1,0,0,1,0-1.42l5.18-5.17a10.59,10.59,0,0,1,10.84-2.6A1,1,0,0,1,20,8.78a1,1,0,0,1-1.26.64,8.66,8.66,0,0,0-8.81,2.11L5.41,16l3.3,3.29a1,1,0,0,1,0,1.42A1,1,0,0,1,8,21Z"
      />
      <path
        fill="currentColor"
        d="M16,25A10.61,10.61,0,0,1,9.5,22.79a1,1,0,1,1,1.22-1.58,8.69,8.69,0,0,0,11.4-.74L26.59,16l-4.47-4.47a9,9,0,0,0-.74-.66,1,1,0,1,1,1.24-1.56,8.77,8.77,0,0,1,.91.81l5.18,5.17a1,1,0,0,1,0,1.42l-5.18,5.17A10.62,10.62,0,0,1,16,25Z"
      />
      <path
        fill="currentColor"
        d="M16,20a4,4,0,0,1-2.67-1,1,1,0,1,1,1.34-1.49A2,2,0,0,0,16,18a2,2,0,0,0,2-2,2,2,0,0,0-.51-1.33A1,1,0,1,1,19,13.33,4,4,0,0,1,16,20Z"
      />
      <path
        fill="currentColor"
        d="M13.17,16a1,1,0,0,1-.33-.06,1,1,0,0,1-.61-1.27,3.94,3.94,0,0,1,2.44-2.44,1,1,0,0,1,.66,1.88,2,2,0,0,0-1.22,1.22A1,1,0,0,1,13.17,16Z"
      />
      <path
        fill="currentColor"
        d="M8,25a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42l16-16a1,1,0,1,1,1.42,1.42l-16,16A1,1,0,0,1,8,25Z"
      />
    </svg>
  );
}
