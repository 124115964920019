import React, { useState, useEffect } from 'react';

import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router';
import { get,del } from '../common/utils/http.js';
import { baseApi } from '../common/config.js';

import CycleEditor from './cycle-editor.js';
import Confirmation from '../components/confirmation.js';
import { IconDelete } from '../components/icons/icons.js';

import { clientsState } from '../state/clients.js';

export default function CycleEdit() {
  const clients = useRecoilValue(clientsState);
  const { cycleId } = useParams();
  const [cycle, setCycle] = useState({ _id: 0 });
  const [client, setClient] = useState({ _id: 0 });
  const [isDelete, setIsDelete] = useState(false);
  const [isDeleted, setDeleted] = useState(false);

  const onDeleteAccept = function () {
    del(`${baseApi}/cycles/admin/${cycleId}`).then((data) => {
      setDeleted(true);
    });
  };

  useEffect(() => {
    get(`${baseApi}/cycles/${cycleId}`).then((data) => {
      const client = clients.find((cl) => cl._id === data.clientId);
      setCycle(data);
      setClient(client);
    });
  }, [cycleId]);

  if (cycle._id === 0 || client._id === 0) {
    return <p>Loading...</p>;
  }

  return (
    <div className="flex-1 p-12 overflow-y-auto">
      <div className="border border-light bg-bglight p-4 mb-6 max-w-main">
        <div className="flex justify-between">
          <h2 className="text-2xl text-dark mb-4">
            {isDeleted && 'DELETED: '}Lot {cycle.data.lotnumber}
          </h2>
          <div className="relative">
            <button
              className="button button-danger"
              disabled={isDeleted}
              onClick={() => {
                setIsDelete(!isDelete);
              }}
            >
              <IconDelete className="w-4 h-4 inline-block" /> Delete
            </button>
            {isDelete && !isDeleted && (
              <Confirmation
                title={`Delete lot ${cycle.data.lotnumber}`}
                text="Are you sure you want to permanently delete this cycle?"
                onAccept={onDeleteAccept}
                onCancel={() => {
                  setIsDelete(!isDelete);
                }}
              />
            )}
          </div>
        </div>
        <hr className="border-light mb-4" />

        <CycleEditor cycle={cycle} client={client} cycleId={cycleId} />
      </div>
    </div>
  );
};
