import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router';
import Classnames from 'classnames';
import { getSimpleDate } from '../common/utils/date.js';
import { prettyNumber } from '../common/utils/numbers.js';
import range from '../common/utils/range.js';
import { baseApi } from '../common/config.js';

import useDelivery from './use-delivery.js';
import { getFile } from '../common/utils/http.js';
import { clientsState } from '../state/clients.js';

function Delivery(props) {
  const clients = useRecoilValue(clientsState);
  const { deliveryId } = useParams();

  const { delivery } = useDelivery(deliveryId);

  function downloadFile() {
    let anchor = document.createElement('a');
    document.body.appendChild(anchor);
    const fileUrl = `${baseApi}/delivery/pdf/${delivery._id}`;

    return getFile(fileUrl)
      .then((blobFile) => {
        let objectUrl = window.URL.createObjectURL(blobFile);

        anchor.href = objectUrl;
        anchor.download = 'data.pdf';
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      })
      .then(() => {
        anchor.remove();
      });
  }

  if (!delivery) {
    return <p>Loading...</p>;
  }

  const client = clients.find((client) => client._id === delivery.clientId);
  const total = delivery.cycles.reduce((sum, cycle) => {
    return sum + cycle.data.aluminium.reduce((s, i) => s + i, 0);
  }, 0);
  const mostItems = delivery.cycles.reduce((max, item) => {
    return Math.max(max, item.data.aluminium.length);
  }, 1);
  const tdRange = range(0, mostItems - 1);
  const thRange = range(0, mostItems - 2);

  return (
    <div className="flex-1 p-12 overflow-y-auto">
      <div className="border border-light bg-bglight p-4 mb-6 max-w-main">
        <div className="flex justify-between">
          <h2 className="text-2xl text-dark mb-4">{client.name}</h2>
          <div className="text-right">
            Total {prettyNumber(total)} kg
            <br />
            {getSimpleDate(new Date(delivery.createdAt))}
          </div>
        </div>
        <hr className="border-light mb-4" />
        <div>
          <div className="flex w-full justify-between mb-8">
            <table className="table-auto border-collapse border border-bordercl w-full mb-2">
              <thead className="bg-bgdark text-light h-12 text-1">
                <tr>
                  <th className="w-40">Lot</th>
                  <th className="w-40">Date</th>
                  <td>Weight</td>
                  {thRange.map(() => (
                    <td></td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {delivery.cycles.map((cycle, i) => {
                  const className = Classnames('h-12 border border-light', { 'bg-bglight': i % 2 });
                  return (
                    <tr className={className} key={cycle._id}>
                      <td className="pl-6">{cycle.data.lotnumber}</td>
                      <td>{getSimpleDate(new Date(cycle.createdAt))}</td>
                      {tdRange.map((s, i) => (
                        <td key={i}>{cycle.data.aluminium[i] || ''}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end">
            <button onClick={downloadFile} className="button mr-4">
              Download
            </button>
            <Link to="/delivery" className="button mr-4">
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Delivery;
