import React, { useState, useEffect } from 'react';
import { NavLink, Routes, Route, Navigate } from 'react-router-dom';
import DeliveryTypeSelect from './delivery-type-select.js';
import MultiDatePicker from '../components/multi-date-picker.js';
import { getTomorrow, getStartOfMonth, getMonthsAgo, startOfYear, endOfYear, getPrettierDate } from '../common/utils/date.js';
import { PICKER_TIMESPAN } from '../common/enums.js';

import DeliveriesView from './deliveries.js';
import DeliveryView from './delivery.js';
import ReceivedView from './received.js';

export default function DeliveryWrapper() {
  const [from, setFrom] = useState(getStartOfMonth());
  const [to, setTo] = useState(getTomorrow());
  const [typeTimespan, setTypeTimespan] = useState(PICKER_TIMESPAN.CURRENT);

  useEffect(() => {
    switch (typeTimespan) {
      case PICKER_TIMESPAN.CURRENT: {
        setFrom(getStartOfMonth());
        setTo(getTomorrow());
        break;
      }
      case PICKER_TIMESPAN.LASTMONTH: {
        setFrom(getMonthsAgo(1));
        const to = getStartOfMonth();
        to.setDate(to.getDate() - 1);
        setTo(to);
        break;
      }
      case PICKER_TIMESPAN.L3MONTHS: {
        setFrom(getMonthsAgo(2));
        setTo(getTomorrow());
        break;
      }
      case PICKER_TIMESPAN.L6MONTHS: {
        setFrom(getMonthsAgo(5));
        setTo(getTomorrow());
        break;
      }
      case PICKER_TIMESPAN.L12MONTHS: {
        setFrom(getMonthsAgo(11));
        setTo(getTomorrow());
        break;
      }
      case PICKER_TIMESPAN.CURRYEAR: {
        setFrom(startOfYear());
        setTo(getTomorrow());
        break;
      }
      case PICKER_TIMESPAN.LASTYEAR: {
        const lastYear = (new Date()).getFullYear() - 1;
        setFrom(startOfYear(lastYear));
        setTo(endOfYear(lastYear));
        break;
      }
    }
  }, [typeTimespan]);

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="bg-bglight flex px-12">
        <div>
          <div className="my-4">
            <DeliveryTypeSelect setTypeTimespan={setTypeTimespan} typeTimespan={typeTimespan} />
            <MultiDatePicker
              className="input ml-2 py-2 select-none h-8 w-8"
              setFrom={setFrom}
              from={from}
              setTo={setTo}
              to={to}
              setTimespan={() => {}}
            />
          </div>
          <h1 className="text-xl py-6 font-semi">
            {getPrettierDate(from)} - {getPrettierDate(to)}
          </h1>
        </div>
        <div className="flex-1"></div>
        <div className="mb-4 mt-8">
          <NavLink to="/delivery/sent" className={(d) => ( d.isActive ? 'nav-button active' : 'nav-button')}>
            Sent
          </NavLink>
          <NavLink to="/delivery/received" className={(d) => ( d.isActive ? 'nav-button active' : 'nav-button')}>
            Received
          </NavLink>
        </div>
      </div>

      <div className="cycles-wrapper max-w-6xl p-12">
        <Routes>
          <Route index element={<Navigate replace to="/delivery/sent" />} />
          <Route path="sent" element={<DeliveriesView from={from} to={to} />} />
          <Route path="sent/:deliveryId" element={<DeliveryView />} />
          <Route path="received" element={<ReceivedView from={from} to={to} />} />
        </Routes>
      </div>
    </div>
  );
}
