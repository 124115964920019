import React from 'react';
import PropTypes from 'prop-types';

export default function Confirmation({ title, text, onAccept, onCancel }) {
  return (
    <div className="absolute mt-2 right-0 p-4 min-w-md bg-bglight text-dark rounded border z-10">
      <h2 className="text-xl mb-4">{title}</h2>
      <p className="text-sm mb-4">{text}</p>
      <div className="flex justify-end">
        <button className="button text-bgdark mr-4" onClick={onCancel}>
          Cancel
        </button>
        <button className="button text-bgdark" onClick={onAccept}>
          Accept
        </button>
      </div>
    </div>
  );
}
Confirmation.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};
