import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { immutableUpdate, deepClone } from '../common/utils/obj.js';

function convertToSlug(text) {
  return text
    .toUpperCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '');
}

const Client = function (props) {
  const { client, toggleEditMode, onSave } = props;
  const [editMdl, setEditMdl] = useState(deepClone(client));
  const [newmatname, setNewMatName] = useState('');

  function onValueChange(e) {
    const target = e.target;
    const name = e.target.name;
    const isNumber = target.hasAttribute('data-is-number');
    const isCheckbox = target.hasAttribute('data-is-checkbox');

    const value = isNumber ? Number(target.value) : isCheckbox ? target.checked : target.value;

    const newObj = immutableUpdate(editMdl, name, value);

    setEditMdl(newObj);
  }

  function removeMaterial(matId) {
    const mdl = deepClone(editMdl);

    mdl.config.materials = mdl.config.materials.filter((mat) => mat.id !== matId);

    setEditMdl(mdl);
  }

  function toggleSeparateMaterial(matId) {
    const mdl = deepClone(editMdl);

    const ind = mdl.config.materials.findIndex((mat) => mat.id === matId);
    mdl.config.materials[ind].separate = !mdl.config.materials[ind].separate;

    setEditMdl(mdl);
  }

  function addMaterial() {
    const mdl = deepClone(editMdl);

    mdl.config.materials.push({
      id: convertToSlug(newmatname),
      name: newmatname
    });
    setEditMdl(mdl);
    setNewMatName('');
  }

  function onSavePressed(e) {
    e.preventDefault();
    onSave(editMdl);
  }

  return (
    <div className="flex flex-col max-w-3xl border border-light rounded-lg p-4 bg-whiteish mb-4">
      <div className="flex justify-between mb-2">
        <input className="input text-xl py-1" value={editMdl.name} name="name" onChange={onValueChange} />
      </div>
      <div className="flex mb-4">
        <label className="block mr-4">
          <span className="block text-light">Code</span>
          <input className="input w-20 text-sm py-1 h-8" value={editMdl.code} onChange={onValueChange} name="code" />
        </label>
        <label className="block mr-4">
          <span className="block text-light">Color</span>
          <input
            type="color"
            className="input w-8 h-8 p-1 text-sm"
            name="config.color"
            value={editMdl.config.color}
            onChange={onValueChange}
          />
        </label>
        <label className="block mr-4">
          <span className="block text-light">Yield Goal (%)</span>
          <input
            className="input w-20 text-sm py-1 h-8"
            value={editMdl.config.yieldgoal}
            onChange={onValueChange}
            data-is-number
            name="config.yieldgoal"
          />
        </label>
        <div className="flex-1"></div>
        <button className="button py-0 leading-none text-sm h-10 mr-6" onClick={toggleEditMode}>
          Cancel
        </button>
        <button className="button bg-bgdark text-white py-0 leading-none text-sm h-10 mr-6" onClick={onSavePressed}>
          Save
        </button>
      </div>
      <hr className="border-light mb-4" />
      <div className="flex">
        <div className="flex flex-col flex-1 mr-2">
          <h3 className="text-lg mb-2">Client Config</h3>
          <label className="border border-light mb-2 rounded bg-white py-1 px-2">
            <input
              type="checkbox"
              data-is-checkbox
              checked={editMdl.config.containers}
              onChange={onValueChange}
              name="config.containers"
            />
            Use Containers
          </label>
          {editMdl.config.containers && (
            <label className="border border-light mb-2 rounded bg-white py-1 px-2">
              <input
                type="checkbox"
                data-is-checkbox
                checked={editMdl.config.allowcontainercombine}
                onChange={onValueChange}
                name="config.allowcontainercombine"
              />
              Allow container to combine
            </label>
          )}
          <label className="border border-light mb-2 rounded bg-white py-1 px-2">
            <input
              type="checkbox"
              data-is-checkbox
              checked={editMdl.config.salt}
              onChange={onValueChange}
              name="config.salt"
            />
            Use Salt
          </label>
          <label className="border border-light mb-2 rounded bg-white py-1 px-2">
            <input
              type="checkbox"
              data-is-checkbox
              checked={editMdl.config.notes}
              onChange={onValueChange}
              name="config.notes"
            />
            Use notes
          </label>
        </div>
        <div className="flex flex-col flex-1 ml-2">
          <h3 className="text-lg mb-2">Materials</h3>
          {editMdl.config.materials.map((mat) => {
            return (
              <div key={mat.id} className="border border-light mb-2 rounded bg-white flex justify-between py-1 px-2">
                <button className="block w-6" onClick={() => toggleSeparateMaterial(mat.id)}>
                  {mat.separate ? '★' : '☆'}
                </button>
                {mat.name}
                <button className="border border-dark w-8" onClick={() => removeMaterial(mat.id)}>
                  -
                </button>
              </div>
            );
          })}
          <div className="border border-light mb-2 rounded bg-white flex justify-between py-1 px-2">
            <input
              type="text"
              max="30"
              placeholder="New Material"
              value={newmatname}
              onChange={(e) => setNewMatName(e.target.value)}
            />
            <button className="border border-dark w-8" onClick={() => addMaterial()}>
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
Client.propTypes = {
  client: PropTypes.object
};

export default Client;
