import React from 'react';
import Classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { prettyNumber } from '../common/utils/numbers.js';
import { getSimpleDate } from '../common/utils/date.js';
import IconEdit from '../components/icons/edit.js';

import { clientsState } from '../state/clients.js';
import useDeliveries from './use-deliveries.js';

function add(items) {
  return items.reduce((sum, item) => {
    return sum + item;
  }, 0);
}

export default function DeliveryList(props) {
  const clients = useRecoilValue(clientsState);
  const { from, to } = props;

  const { deliveries, pageNumber, getPrevPage, getNextPage, numberOfPages, sum } = useDeliveries({ from, to });

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="flex flex-row flex-wrap gap-2 mb-12">
        {clients.map((client) => {
          const totalForMaterials = sum[client._id] || [];

          return totalForMaterials.map((sum) => (
            <div key={client._id + sum.material} className="border border-light rounded-xl p-2 bg-bgdark flex-24 flex flex-col">
              <div className="flex justify-between">
                <h2 className="text-xl pb-2 text-white">{sum.material}</h2>
                <span className="text-primary">{client.name}</span>
              </div>
              <div className="flex-1 flex flex-col rounded-lg overflow-hidden bg-whiteish p-2">
                <table>
                  <tbody>
                    <tr>
                      <td>Count</td>
                      <td className="text-right">{sum.count}</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td className="text-right">{prettyNumber(sum.total)} kg</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ));
        })}
      </div>
      <table className="table-auto border-collapse border border-bordercl w-full mb-2">
        <thead className="bg-bgdark text-light h-12 text-1 mb-2">
          <tr>
            <th>Client</th>
            <th>Date</th>
            <th>Aluminium</th>
            <th>Lots</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {deliveries.map((delivery, i) => {
            const client = clients.find((client) => client._id === delivery.clientId);
            const className = Classnames('h-12 border border-light', { 'bg-bglight': i % 2 });
            return (
              <tr key={delivery._id} className={className}>
                <td className="pl-6">{client ? client.name : 'N/A'}</td>
                <td>{getSimpleDate(new Date(delivery.createdAt))}</td>
                <td>{prettyNumber(delivery.cycles.reduce((sum, cycle) => sum + add(cycle.data.aluminium), 0))} kg</td>
                <td>{delivery.cycles.length}</td>
                <td>
                  <Link to={`/delivery/sent/${delivery._id}`}>
                    <IconEdit className="w-6 h-6" />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-between mb-8">
        <button disabled={pageNumber === 0} className="button mr-8" onClick={getPrevPage}>
          Previous
        </button>
        <button disabled={pageNumber === numberOfPages} className="button" onClick={getNextPage}>
          Next
        </button>
      </div>
    </div>
  );
}

