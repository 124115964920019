import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { baseApi } from '../common/config.js';
import { post } from '../common/utils/http.js';
import { IconCheck } from '../components/icons/icons.js';

function getInitialMember() {
  return {
    name: '',
    email: '',
    username: '',
    password: ''
  };
}

function isModelInvalid(mdl) {
  return ['name', 'email', 'username', 'password'].some((key) => {
    return mdl[key] === '';
  });
}

function MemberCreate() {
  const [member, setMember] = useState(getInitialMember());
  const [errors, setErrors] = useState([]);
  const [isCreated, setCreated] = useState(false);

  function onCreateMember() {
    const { name, email, username, password } = member;
    if (isModelInvalid(member)) {
      return setErrors(['All fields must be fileld']);
    }

    setErrors([]);

    post(`${baseApi}/users`, {
      name,
      email,
      username,
      password
    })
      .then(() => {
        setCreated(true);
        setMember(getInitialMember());
      })
      .catch((ex) => {
        setErrors(ex.details || [err.message]);
      });
  }

  function setMemberVar(key) {
    return function (e) {
      setMember({
        ...member,
        [key]: e.target.value
      });
    };
  }

  return (
    <div className="flex-1 p-12 overflow-y-auto">
      <div className="user-create max-w-lg">
        <h2 className="text-2xl text-gray-800">Create User</h2>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="create-name">
            Full Name
          </label>
          <input
            className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="create-name"
            type="text"
            name="name"
            onChange={setMemberVar('name')}
            value={member.name}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="create-email">
            Email
          </label>
          <input
            className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="create-email"
            type="email"
            name="email"
            onChange={setMemberVar('email')}
            value={member.email}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="create-username">
            Username
          </label>
          <input
            className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="create-username"
            type="text"
            name="username"
            onChange={setMemberVar('username')}
            value={member.username}
            autoComplete="off"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="create-password">
            Password
          </label>
          <input
            className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="create-password"
            type="password"
            name="password"
            onChange={setMemberVar('password')}
            value={member.password}
            autoComplete="off"
          />
        </div>

        <div className="flex justify-end">
          <Link className="button mr-4" to="/members">
            {isCreated ? 'Back' : 'Cancel'}
          </Link>
          <button className="button button-primary" onClick={onCreateMember}>
            {isCreated ? <IconCheck className="w-6 h-6 text-white" /> : 'Create'}
          </button>
        </div>
        {errors && (
          <ul>
            {errors.map((err) => (
              <li>{err}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default MemberCreate;
