import React, { Component } from 'react';

import UserPassword from './password.js';

function UserSettings() {

  return (
    <div className="flex-1 p-12 overflow-y-auto">
      <div className="user-settings max-w-lg">
        <UserPassword />
      </div>
    </div>
  );
}

export default UserSettings;
