import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { clientsState } from '../state/clients.js';

import CycleEditor from './cycle-editor.js';

const createNewCycle = function (clientId) {
  return {
    createdAt: new Date(),
    clientId,
    state: 'FINISHED',
    data: {
      dross: [{ container: '', weight: 0 }],
      oxygen: 0,
      oil: 0,
      salt: 0,
      cycletime: 0,
      aluminium: [0],
      lotnumber: 0,
      material: '',
      notes: ''
    }
  };
};

const CycleEdit = (params) => {
  const clients = useRecoilValue(clientsState);

  const [cycle, setCycle] = useState(createNewCycle(clientId));
  const [client, setClient] = useState(null);
  const [clientId, setClientId] = useState('');

  useEffect(() => {
    setClient(clients.find((c) => c._id === clientId) || null);
  }, [clientId]);

  return (
    <div className="flex-1 p-12 overflow-y-auto">
      <div className="border border-light bg-bglight p-4 mb-6 max-w-main">
        <div className="flex justify-between">
          <h2 className="text-2xl text-dark mb-4">New lot</h2>
          <div>
            <select
              className="input bg-white w-full"
              id="input-client-select"
              onChange={(e) => setClientId(e.currentTarget.value)}
              value={clientId}
            >
              {clientId === '' && <option value="">Select Client</option>}
              {clients.map((cl) => (
                <option key={cl._id} value={cl._id}>
                  {cl.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <hr className="border-light mb-4" />
        {client === null ? <p>Select client</p> : <CycleEditor cycle={cycle} client={client} />}
      </div>
    </div>
  );
};

export default CycleEdit;
