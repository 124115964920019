import React from 'react';

export default function Logo({ className = '' } = {}){
  return <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 236.2559 249.5705">
    <path fill="currentColor" d="M47.3876,103.9162,19.7505,113.9753a29.4106,29.4106,0,1,0,37.6962,17.5779Z"/>
    <path fill="currentColor" d="M29.9687,3.5634,76.9108,132.536a58.8212,58.8212,0,0,0,75.3917,35.1558L105.3605,38.7192A58.8211,58.8211,0,0,0,29.9687,3.5634Z"/>
    <path fill="currentColor" d="M207.3278,94.5414a58.8211,58.8211,0,0,0-75.3917-35.1558h-.0006L158.76,133.0842A58.8213,58.8213,0,0,0,234.1517,168.24h.0006Z"/>
    <path fill="currentColor" d="M65.0518,211.4679q4.6009,4.563,4.6015,13.6885v23.8682h-9.75v-22.62q0-5.458-2.4179-8.2286a8.7241,8.7241,0,0,0-6.9415-2.77,10.1712,10.1712,0,0,0-7.8789,3.2373q-2.9648,3.2373-2.9638,9.2432v21.1377h-9.75v-22.62q0-5.458-2.418-8.2286a8.7238,8.7238,0,0,0-6.9414-2.77,10.2,10.2,0,0,0-7.917,3.1982Q9.75,221.804,9.75,227.8869v21.1377H0V207.3722H9.2812v5.3047a15.1691,15.1691,0,0,1,5.8506-4.29,19.8719,19.8719,0,0,1,7.8-1.4824,18.8425,18.8425,0,0,1,8.3067,1.7548,13.4873,13.4873,0,0,1,5.7334,5.1875,17.3575,17.3575,0,0,1,6.63-5.1093,21.55,21.55,0,0,1,8.97-1.833Q60.4492,206.9045,65.0518,211.4679Z"/>
    <path fill="currentColor" d="M121.5225,231.3185H88.84a11.3222,11.3222,0,0,0,4.4854,7.293,14.7014,14.7014,0,0,0,9.0088,2.6914,15.18,15.18,0,0,0,11.31-4.5244l5.2265,6.0058a17.61,17.61,0,0,1-7.0976,5.07A28.1318,28.1318,0,0,1,90.01,246.841a19.6658,19.6658,0,0,1-8.0733-7.6055A21.47,21.47,0,0,1,79.09,228.1984a21.7911,21.7911,0,0,1,2.77-10.959,19.637,19.637,0,0,1,7.6826-7.6045,22.4147,22.4147,0,0,1,11.0762-2.73,21.855,21.855,0,0,1,10.8808,2.6914,19.0606,19.0606,0,0,1,7.4883,7.5654,22.9485,22.9485,0,0,1,2.69,11.2715Q121.6777,229.4465,121.5225,231.3185ZM92.7012,217.4738a11.6447,11.6447,0,0,0-3.9395,7.3711h23.6338a11.4948,11.4948,0,0,0-3.8213-7.332,11.6264,11.6264,0,0,0-7.956-2.8086A11.7611,11.7611,0,0,0,92.7012,217.4738Z"/>
    <path fill="currentColor" d="M131.0381,191.1486h9.75v57.876h-9.75Z"/>
    <path fill="currentColor" d="M180.6436,246.7629a11.4725,11.4725,0,0,1-4.1729,2.1054,18.8,18.8,0,0,1-5.1865.7022q-6.8643,0-10.6084-3.5879T156.9316,235.53V215.4845h-6.8632v-7.8h6.8632V191.6916h0a9.75,9.75,0,0,1,9.75,9.75v6.2431h11.1543v7.8H166.6816V235.297a6.5883,6.5883,0,0,0,1.4825,4.6407,5.5585,5.5585,0,0,0,4.29,1.5986,8.564,8.564,0,0,0,5.46-1.7158Z"/>
    <path fill="currentColor" d="M189.1455,198.8312a5.75,5.75,0,0,1,0-8.3457,6.2635,6.2635,0,0,1,4.4453-1.6768,6.4426,6.4426,0,0,1,4.4463,1.5987,5.1587,5.1587,0,0,1,1.794,4.0176,5.88,5.88,0,0,1-1.7549,4.3281,6.0712,6.0712,0,0,1-4.4854,1.7558A6.2555,6.2555,0,0,1,189.1455,198.8312Zm-.4678,8.541h9.75v41.6524h-9.75Z"/>
    <path fill="currentColor" d="M236.2559,246.7629a11.4725,11.4725,0,0,1-4.1729,2.1054,18.8,18.8,0,0,1-5.1865.7022q-6.8643,0-10.6084-3.5879T212.5439,235.53V215.4845h-6.8632v-7.8h6.8632V191.6916h0a9.75,9.75,0,0,1,9.75,9.75v6.2431h11.1543v7.8H222.2939V235.297a6.5883,6.5883,0,0,0,1.4825,4.6407,5.5585,5.5585,0,0,0,4.29,1.5986,8.564,8.564,0,0,0,5.46-1.7158Z"/>
  </svg>
}


export function LogoSmall({ className = '' } = {}){
  return <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 233.7615 171.8034">
    <path fill="currentColor" d="M46.9967,103.9162,19.36,113.9753a29.4106,29.4106,0,1,0,37.6962,17.5779Z"/>
    <path fill="currentColor" d="M29.5778,3.5634,76.52,132.536a58.8212,58.8212,0,0,0,75.3917,35.1558L104.97,38.7192A58.8211,58.8211,0,0,0,29.5778,3.5634Z"/>
    <path fill="currentColor" d="M206.9369,94.5414a58.8211,58.8211,0,0,0-75.3917-35.1558h-.0006l26.8245,73.6986A58.8214,58.8214,0,0,0,233.7609,168.24h.0006Z"/>
  </svg>
}
