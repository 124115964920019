import React from 'react';

export default function IconCheck({ className = '' } = {}) {
  return (
    <svg className={className} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M21.33,57.82,0,36.53l5.87-5.87L21.33,46.09,58.13,9.36,64,15.23,21.33,57.82"
      />
    </svg>
  );
}
