import React from 'react';
import PropTypes from 'prop-types';
import Classnames from 'classnames';
import { useRecoilValue } from 'recoil';
import { prettyNumber } from '../common/utils/numbers.js';
import { getSimpleDate } from '../common/utils/date.js'

import { clientsState } from '../state/clients.js';
import useReceived from './use-received.js';


function add(items, selector){
  return items.reduce((sum, item) => {
    return sum + selector ? item[selector] : item;
  }, 0);
}


function ReceivedList(props) {
  const { from, to } = props;
  const clients = useRecoilValue(clientsState);
  
  const {
    received,
    pageNumber,
    getPrevPage,
    getNextPage,
    lastPage
  } = useReceived({ from, to });

  return (
    <div className="flex-1 overflow-y-auto">
      <table className="table-auto border-collapse border border-bordercl w-full mb-2">
        <thead className="bg-bgdark text-light h-12 text-1 mb-2">
          <tr>
            <th>Client</th>
            <th>Date</th>
            <th>Amout</th>
            <th>Material</th>
            <th>Container</th>
          </tr>
        </thead>
        <tbody>
        {received.map((item, i) => {
          const client = clients.find((client) => client._id === item.clientId);
          const className = Classnames('h-12 border border-light', { 'bg-bglight': i % 2 });
          return <tr key={item._id} className={className}>
              <td className="pl-6">{client ? client.name : 'N/A'}</td>
              <td>{getSimpleDate(new Date(item.createdAt))}</td>
              <td>{prettyNumber(item.weight)} kg</td>
              <td>{item.material}</td>
              <td>{item.container || 'N/A'}</td>
            </tr>
        })}
        {received.length === 0 &&
          <tr>
            <td colSpan="5" className="pl-6 h-12 text-light">Nothing here</td>
          </tr>
          }
        </tbody>
      </table>
      <div className="flex justify-between mb-8">
        <button disabled={pageNumber === 0} className="button mr-8" onClick={getPrevPage}>
          Previous
        </button>
        <button disabled={lastPage} className="button" onClick={getNextPage}>
          Next
        </button>
      </div>
    </div>
  );
}

ReceivedList.propTypes = {
  clients: PropTypes.array,
  from: PropTypes.object,
  to: PropTypes.object
};

export default ReceivedList;
