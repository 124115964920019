import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { LogoSmall } from '../icons/logo.js';
import User from './user.js';

const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAI', 'JUN', 'JUL', 'AUG', 'SEP', 'OKT', 'NOV', 'DES'];

function Menu(props) {
  const { userrole, user, onLogout } = props;

  const now = new Date();
  const weekday = now.getDay();
  const month = now.getMonth();
  const date = now.getDate();

  const isAdmin = userrole === 'admin';
  const isManager = userrole === 'manager';
  const isUser = userrole === 'user';

  const activeClassName = 'bg-light text-black ml-2 pl-6 rounded-full rounded-r-none text-2xl font-semi';
  const inactiveClassName = 'text-light p-2 mb-3 text-2xl';

  return (
    <div className="main-menu border-b-2">
      <div className="flex justify-between pr-4 mb-32">
        <p className="flex flex-col">
          <span className="text-sm">{WEEKDAYS[weekday]}</span>
          <span className="font-bold">
            {date}. {MONTHS[month]}
          </span>
        </p>
        <p className="">{user.company.name}</p>
      </div>
      <div className="flex flex-col flex-1 pl-4">
        <NavLink
          className={(navigationData) => navigationData.isActive ? activeClassName : inactiveClassName }
          to="/"
        >
          Dashboard
        </NavLink>
        <NavLink
          className={(navigationData) => navigationData.isActive ? activeClassName : inactiveClassName }
          to="/reports"
        >
          Reports
        </NavLink>
        <NavLink
          className={(navigationData) => navigationData.isActive ? activeClassName : inactiveClassName }
          to="/cycles"
        >
          Cycles
        </NavLink>
        <NavLink
          hidden={!isAdmin}
          className={(navigationData) => navigationData.isActive ? activeClassName : inactiveClassName }
          to="/reports"
        >
          Reports
        </NavLink>
        <NavLink
          className={(navigationData) => navigationData.isActive ? activeClassName : inactiveClassName }
          to="/delivery"
        >
          Delivery
        </NavLink>
        <NavLink
          hidden={!isManager}
          className={(navigationData) => navigationData.isActive ? activeClassName : inactiveClassName }
          to="/members"
        >
          Users
        </NavLink>
        <NavLink
          hidden={!isManager}
          className={(navigationData) => navigationData.isActive ? activeClassName : inactiveClassName }
          to="/clients"
        >
          Clients
        </NavLink>
        <NavLink
          className={(navigationData) => navigationData.isActive ? activeClassName : inactiveClassName }
          to="/settings"
        >
          Settings
        </NavLink>
      </div>
      <div>
        <User user={user} onLogout={onLogout} />
        <div className="flex justify-center text-primary pr-2">
          <LogoSmall className="w-11 mb-6" />
        </div>
      </div>
    </div>
  );
}
Menu.propTypes = {
  user: PropTypes.object,
  userrole: PropTypes.string,
  onLogout: PropTypes.func
};

export default Menu;
