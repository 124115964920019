import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconCheck } from '../components/icons/icons.js';

import { put } from '../common/utils/http.js';
import { baseApi } from '../common/config.js';

class UserPassword extends Component {
  static propTypes = {
    loggedin: PropTypes.bool,
    user: PropTypes.object,
    updatePassword: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onUpdatePassword = this.onUpdatePassword.bind(this);

    this.state = {
      oldpass: '',
      newpass: '',
      newpass2: '',
      errors: [],
      isSaved: false
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState((state) => {
      return {
        ...state,
        [name]: value
      };
    });
  }

  onUpdatePassword() {
    const { oldpass, newpass, newpass2 } = this.state;

    if (oldpass.length === 0 || newpass.length === 0) {
      this.setState({
        isSaved: false,
        errors: ['"password" is not allowed to be empty']
      });
    } else if (newpass !== newpass2) {
      this.setState({
        isSaved: false,
        errors: ['passwords do not match']
      });
    } else {
      put(`${baseApi}/users/selfpassword`, {
        old: oldpass,
        new: newpass
      })
        .then(() => {
          this.setState({
            oldpass: '',
            newpass: '',
            newpass2: '',
            isSaved: true,
            errors: []
          });
        })
        .catch((ex) => {
          console.log(ex.details || ['Error occurred']);
          this.setState({
            errors: ex.details || ['Error occurred']
          });
        });
    }
  }

  render() {
    const { oldpass, newpass, newpass2, errors, isSaved } = this.state;

    return (
      <div className="user-create max-w-lg mb-6">
        <h2 className="text-xl text-dark mb-4">Change password</h2>
        <div className="mb-2">
          <label className="block text-dark text-sm mb-2" htmlFor="create-oldpass">
            Current Password
          </label>
          <input
            className="input w-full"
            id="create-oldpass"
            type="password"
            name="oldpass"
            onChange={this.handleInputChange}
            value={oldpass}
          />
        </div>
        <div className="mb-2">
          <label className="block text-dark text-sm mb-2" htmlFor="create-newpass">
            New Password
          </label>
          <input
            className="input w-full"
            id="create-newpass"
            type="password"
            name="newpass"
            onChange={this.handleInputChange}
            value={newpass}
          />
        </div>
        <div className="mb-4">
          <label className="block text-dark text-sm mb-2" htmlFor="create-newpass2">
            Repeat new password
          </label>
          <input
            className="input w-full"
            id="create-newpass2"
            type="password"
            name="newpass2"
            onChange={this.handleInputChange}
            value={newpass2}
          />
        </div>

        <div className="flex justify-end">
          <Link className="button mr-4" to="/">
            {isSaved ? 'Back' : 'Cancel'}
          </Link>
          <button className="button button-primary" onClick={this.onUpdatePassword}>
            {isSaved ? <IconCheck className="w-6 h-6 text-white" /> : 'Save'}
          </button>
        </div>

        {errors && (
          <ul>
            {errors.map((err) => (
              <li>{err}</li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default UserPassword;
