import { useEffect, useState } from 'react';
import { baseApi } from '../common/config';
import { get } from '../common/utils/http';
import { toSimpleDate } from '../common/utils/date.js';

function useDeliveries({ from, to }){
  const [sum, setSum] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [pagedDeliveries, setPagedDeliveries] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const limit = 5;
  
  useEffect(() => {
    get(`${baseApi}/delivery?limit=${limit}&skip=${pageNumber * limit}&from=${toSimpleDate(from)}&to=${toSimpleDate(to)}`)
      .then((data) => {
        setDeliveries(data.data); //Check current version?!
        setSum(data.sum);
        setNumberOfPages(Math.floor(data.data.length / limit));
      });
  }, [from, to]);

  useEffect(() => {
    setPagedDeliveries(deliveries.slice(pageNumber * limit, (pageNumber + 1) * limit));
  }, [pageNumber, deliveries])


  function getPrevPage() {
    setPageNumber(Math.max(pageNumber - 1, 0));
  }

  function getNextPage() {
    setPageNumber(pageNumber + 1);
  }

  return {
    deliveries: pagedDeliveries,
    sum,
    pageNumber,
    numberOfPages,
    getPrevPage,
    getNextPage
  }
}

export default useDeliveries;