export function getSimpleDateTime(date) {
  const minutes = date.getMinutes();

  return `${date.getDate()}/${date.getMonth() + 1} ${date.getHours()}:${minutes < 10 ? '0' + minutes : minutes}`;
}

export function getSimpleDate(date) {
  return `${date.getDate()}/${date.getMonth() + 1} ${date.getFullYear()}`;
}

export function getStartOfMonth(month, year) {
  if (month == undefined) {
    const date = new Date();
    date.setDate(1);
    return date;
  }
  return new Date(year, month, 1);
}

export function getEndOfMonth(month, year) {
  return new Date(year, month + 1, 0);
}

export function startOfYear(year = (new Date()).getFullYear()) {
  const date = new Date(year, 0, 1);

  return date;
}

export function endOfYear(year = (new Date()).getFullYear()) {
  const date = new Date(year, 11, 31);

  return date;
}

function getMonthSpan(month, year) {
  return {
    start: new Date(year, month, 1),
    end: new Date(year, month + 1, 0)
  };
}

export function getTomorrow() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
}

export function daysInMonth(month, year) {
  return getEndOfMonth(month, year).getDate();
}

export function getStartOfToday() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

export function toSimpleDate(date) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return `${year}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day}`;
}

export function isValidDate(date) {
  return date.getTime() === date.getTime();
}

export function getMonthsAgo(num) {
  const today = new Date();
  today.setDate(1);
  today.setMonth(today.getMonth() - num);
  return today;
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const shortmonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getPrettierDate = function (date) {
  const year = date.getFullYear();
  const month = shortmonths[date.getMonth()];
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

  return `${day} ${month.toUpperCase()} ${year}`;
};

export const getPrettyDate = function (date) {
  const year = date.getFullYear();
  const correctedMonth = date.getMonth() + 1;
  const month = correctedMonth < 10 ? '0' + correctedMonth : correctedMonth;
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

  return `${day}/${month} ${year}`;
};
