import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { get } from '../common/utils/http.js';
import { baseApi } from '../common/config.js';

import { IconEdit } from '../components/icons/icons.js';
import { useRecoilValue } from 'recoil';
import { clientsState } from '../state/clients.js';

export default function Cycles() {
  const clients = useRecoilValue(clientsState);
  const [cycles, setCycles] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const limit = 12;

  const getFullDate = function (date) {
    const minutes = date.getMinutes();

    return `${date.getDate()}/${date.getMonth() + 1} ${date.getFullYear()} ${date.getHours()}:${
      minutes < 10 ? '0' + minutes : minutes
    }`;
  };

  useEffect(() => {
    get(`${baseApi}/cycles?limit=${limit}&skip=${pageNumber * limit}`).then((data) => {
      const newData = data.map((cycle) => {
        const client = clients.find((cl) => cl._id === cycle.clientId);

        const totalAluminium = cycle.data.aluminium ? cycle.data.aluminium.reduce((sum, al) => sum + al, 0) : 0;
        const totalDross = cycle.data.dross ? cycle.data.dross.reduce((sum, dross) => sum + dross.weight, 0) : 0;

        return {
          id: cycle._id,
          client: client.name,
          lotnumber: cycle.data.lotnumber,
          date: getFullDate(new Date(cycle.createdAt)),
          totalAluminium: totalAluminium,
          totalDross: totalDross,
          salt: cycle.data.salt,
          yield: (totalAluminium / totalDross) * 100,
          state: cycle.state
        };
      });

      setCycles(newData);
    });
  }, [pageNumber]);

  function getPrevPage() {
    setPageNumber(pageNumber - 1);
  }

  function getNextPage() {
    setPageNumber(pageNumber + 1);
  }

  return (
    <div className="flex-1 p-12 overflow-y-auto">
      <div className="cycles-wrapper max-w-main">
        <Link className="block button mb-8 w-40" to="/cycles/new">
          + Add new cycle
        </Link>
        <table className="table-auto border-collapse border border-bordercl w-full mb-8">
          <thead className="bg-bgdark text-light h-12 text-1">
            <tr>
              <th>Lot</th>
              <th>Date</th>
              <th>Client</th>
              <th>Aluminium</th>
              <th>Volume</th>
              <th>Salt</th>
              <th>Yield</th>
              <th>State</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {cycles.map((cycle, i) => {
              const className = classnames('h-12 border border-light', {
                'bg-bglight': i % 2
              });

              return (
                <tr key={cycle.id} className={className}>
                  <td className="px-4 py-2">{cycle.lotnumber}</td>
                  <td className="px-4 py-2">{cycle.date}</td>
                  <td className="px-4 py-2">{cycle.client}</td>
                  <td className="px-4 py-2">{cycle.totalAluminium} kg</td>
                  <td className="px-4 py-2">{cycle.totalDross} kg</td>
                  <td className="px-4 py-2">{cycle.salt} kg</td>
                  <td className="px-4 py-2">{cycle.yield?.toFixed(2)}%</td>
                  <td className="px-4 py-2">{cycle.state}</td>
                  <td className="px-4 py-2">
                    <Link to={`/cycles/${cycle.id}`}>
                      <IconEdit className="w-6 h-6" />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex justify-between mb-8">
          <button disabled={pageNumber === 0} className="button mr-8" onClick={getPrevPage}>
            Previous
          </button>
          <button disabled={cycles.length < limit} className="button" onClick={getNextPage}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

