import React, { useEffect, useState } from 'react';
import { userState } from './state/user';
import { clientsState } from './state/clients';
import { useRecoilState } from 'recoil';
import { checkUserLogin } from './common/auth';

import LoginPage from './login/login.js';
import DefaultLayout from './app-container.js';

export default function App() {
  const [user, setUser] = useRecoilState(userState);
  const [clients, setClients] = useRecoilState(clientsState);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    checkUserLogin()
      .then((data) => {
        setClients(data.clients);
        setUser({ ...data, clients: undefined });
        setReady(true);
      })
      .catch(() => {
        // Not logged in
        setReady(true);
      });
  }, []);

  if (!isReady) {
    return <div>Checking login..</div>;
  }
  if (user === null) {
    return <LoginPage />;
  }
  return <DefaultLayout />;
}
