import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from './state/user';
import { clientsState } from './state/clients';
import Auth from './common/auth';


import Menu from './components/menu/menu.js';

import Home from './home/home.js';
import Members from './members/members.js';
import MemberCreate from './members/create.js';
import MemberDetails from './members/member-detail.js';
import Clients from './clients/clients.js';
import ClientCreate from './clients/client-create.js';
import Settings from './settings/settings.js';
import Cycles from './cycles/cycles.js';
import CycleEdit from './cycles/cycle-edit.js';
import CyclesCreate from './cycles/cycle-new.js';
import UserSettings from './user/user.js';
import Reports from './reports/reports.js';
import Takeout from './takeout/takeout.js';
import TemplateCreate from './takeout/create.js';
import DeliveryWrapper from './delivery/wrapper.js';

import NotFound from './notfound/notfound.js';

export default function DefaultLayout() {
  const [user, setUser] = useRecoilState(userState);
  const [clients, setClients] = useRecoilState(clientsState);

  const isManager = user.role === 'manager';
  const isUser = user.role === 'user';

  function onLogout(){
    Auth.logout();
    setClients([]);
    setUser(null);
  }

  return (
    <div className="main-content flex" style={{ height: '100vh' }}>
      <Menu userrole={user.role} user={user} onLogout={onLogout} />
      <Routes>
        <Route index element={<Home />} />
        <Route path="reports" element={<Reports />} />
        <Route path="settings" element={<Settings />} />
        <Route path="user" element={<UserSettings />} />

        <Route path="exports" element={<Takeout />} />
        <Route path="exports/create" element={<TemplateCreate />} />
        <Route path="exports/:id" element={<TemplateCreate />} />

        <Route path="cycles" element={<Cycles />} />
        <Route path="cycles/new" element={<CyclesCreate />} />
        <Route path="cycles/:cycleId" element={<CycleEdit />} />

        <Route path="clients" element={<Clients />} />
        <Route path="clients/create" element={<ClientCreate />} />

        <Route path="members" element={<Members />} />
        <Route path="members/new" element={<MemberCreate />} />
        <Route path="members/:memberId" element={<MemberDetails />} />

        <Route path="delivery/*" element={<DeliveryWrapper />} />

        <Route path="404" element={<NotFound />} />
      </Routes>
    </div>
  );
}
