import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import MultiDatePicker from '../components/multi-date-picker.js';
import { get } from '../common/utils/http.js';
import { baseApi } from '../common/config.js';
import {
  isValidDate,
  toSimpleDate,
  getTomorrow,
  getStartOfMonth,
  getMonthsAgo,
  getPrettierDate,
  startOfYear,
  endOfYear
} from '../common/utils/date.js';
import { VIEW_TYPES, REPORT_TIMESPAN, REPORT_TYPES } from './enums.js';
import { clientsState } from '../state/clients.js';

import ReportTypeSelect from './report-type-select.js';
import ReportList from './report-lists.js';
import ReportCharts from './report-charts.js';
import YieldBoxes from '../components/yield-box/yield-boxes.js';

const Reports = () => {
  const clients = useRecoilValue(clientsState);

  const [from, setFrom] = useState(getStartOfMonth());
  const [to, setTo] = useState(getTomorrow());
  const [info, setInfo] = useState([]);
  const [data, setData] = useState({});
  const [view, setView] = useState(VIEW_TYPES.OVERVIEW);
  const [reportType, setReportType] = useState(REPORT_TYPES.DAY);
  const [reportTimespan, setReportTimespan] = useState(REPORT_TIMESPAN.CURRENT);

  useEffect(() => {
    if (!isValidDate(from) || !isValidDate(to)) {
      return;
    }
    const fromDate = toSimpleDate(from);
    const toDate = toSimpleDate(to);

    get(`${baseApi}/cycles/report?from=${fromDate}&to=${toDate}&type=${reportType}`).then((data) => {
      // Flatten data
      const items = Object.values(data).reduce((sum, item) => {
        return [...sum, ...item];
      }, []);

      setData(data);

      const newData = clients.map((client) => {
        const separated = [
          { name: 'Dross', id: 'dross' },
          ...client.config.materials.filter((mat) => mat.separate).map((mat) => ({ ...mat, id: mat.id.toLowerCase() }))
        ];
        const clientData = {
          clientName: client.name,
          clientCode: client.code,
          useSalt: client.config.salt,
          separated: separated,
          data: separated.reduce((sum, separation) => {
            return {
              ...sum,
              [separation.id]: {
                totals: items
                  .filter((it) => it.clientCode === client.code && it.material === separation.id)
                  .reduce(
                    (sum, it) => {
                      return {
                        salt: sum.salt + it.salt,
                        volume: sum.volume + it.volume,
                        aluminium: sum.aluminium + it.aluminium,
                        count: sum.count + 1,
                        lots: sum.lots + it.lots
                      };
                    },
                    { input: 0, volume: 0, aluminium: 0, count: 0, lots: 0 }
                  ),
                dates: items
                  .filter((it) => it.clientCode === client.code && it.material === separation.id)
                  .sort((a, b) => (a > b ? -1 : 1))
                  .map((data) => {
                    return {
                      ...data,
                      yield: data.aluminium / data.volume
                    };
                  })
              }
            };
          }, {})
        };

        return clientData;
      });

      setInfo(newData);
    });
  }, [from, to, reportType]);

  useEffect(() => {
    switch (reportTimespan) {
      case REPORT_TIMESPAN.CURRENT: {
        setFrom(getStartOfMonth());
        setTo(getTomorrow());
        setReportType(REPORT_TYPES.DAY);
        break;
      }
      case REPORT_TIMESPAN.LASTMONTH: {
        setFrom(getMonthsAgo(1));
        const to = getStartOfMonth();
        to.setDate(to.getDate() - 1);
        setTo(to);
        setReportType(REPORT_TYPES.DAY);
        break;
      }
      case REPORT_TIMESPAN.L3MONTHS: {
        setFrom(getMonthsAgo(2));
        setTo(getTomorrow());
        setReportType(REPORT_TYPES.MONTH);
        break;
      }
      case REPORT_TIMESPAN.L6MONTHS: {
        setFrom(getMonthsAgo(5));
        setTo(getTomorrow());
        setReportType(REPORT_TYPES.MONTH);
        break;
      }
      case REPORT_TIMESPAN.L12MONTHS: {
        setFrom(getMonthsAgo(11));
        setTo(getTomorrow());
        setReportType(REPORT_TYPES.MONTH);
        break;
      }
      case REPORT_TIMESPAN.CURRYEAR: {
        setFrom(startOfYear());
        setTo(getTomorrow());
        setReportType(REPORT_TYPES.CURRYEAR);
        break;
      }
      case REPORT_TIMESPAN.LASTYEAR: {
        const lastYear = (new Date()).getFullYear() - 1;
        setFrom(startOfYear(lastYear));
        setTo(endOfYear(lastYear));
        setReportType(REPORT_TYPES.LASTYEAR);
        break;
      }
      case REPORT_TIMESPAN.CUSTOM: {
        if (reportType !== REPORT_TYPES.DAY) {
          setReportType(REPORT_TYPES.DAY);
        }
        break;
      }
    }
  }, [reportTimespan]);

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="bg-bglight flex px-12">
        <div>
          <div className="my-4">
            <ReportTypeSelect setReportTimespan={setReportTimespan} reportTimespan={reportTimespan} />
            <MultiDatePicker
              className="input ml-2 py-2 select-none h-8 w-8"
              setFrom={setFrom}
              from={from}
              setTo={setTo}
              to={to}
              setTimespan={setReportTimespan}
            />
          </div>
          <h1 className="text-xl py-6 font-semi">
            {getPrettierDate(from)} - {getPrettierDate(to)}
          </h1>
        </div>
        <div className="flex-1"></div>
        <div className="mb-4 self-end">
          <button
            className={
              view === VIEW_TYPES.OVERVIEW ? 'button button-primary border-white mr-6' : 'button text-black mr-6'
            }
            onClick={() => setView(VIEW_TYPES.OVERVIEW)}
          >
            Overview
          </button>
          <button
            className={
              view === VIEW_TYPES.CHARTS ? 'button button-primary border-white mr-6' : 'button text-black mr-6'
            }
            onClick={() => setView(VIEW_TYPES.CHARTS)}
          >
            Charts
          </button>
          <button
            className={
              view === VIEW_TYPES.TABLES ? 'button button-primary border-white mr-6' : 'button text-black mr-6'
            }
            onClick={() => setView(VIEW_TYPES.TABLES)}
          >
            Tables
          </button>
          <Link to="/exports" className="button inline-block text-black mr-6">
            Export
          </Link>
        </div>
      </div>

      <div className="cycles-wrapper max-w-6xl p-12">
        {view === VIEW_TYPES.OVERVIEW && <YieldBoxes clients={clients} reportData={data} />}
        {view === VIEW_TYPES.CHARTS && <ReportCharts info={info} />}
        {view === VIEW_TYPES.TABLES && <ReportList info={info} />}
      </div>
    </div>
  );
};

export default Reports;
