import IconLock from './lock.js';
import IconSettings from './settings.js';
import IconGarbage from './garbage.js';
import IconHide from './hide.js';
import IconShow from './show.js';
import IconDownload from './download.js';
import IconCheck from './check.js';
import IconCopy from './copy.js';
import IconEdit from './edit.js';
import IconDelete from './delete.js';
import IconRemove from './remove.js';

export { IconLock };
export { IconSettings };
export { IconGarbage };
export { IconHide };
export { IconShow };
export { IconDownload };
export { IconCheck };
export { IconCopy };
export { IconEdit };
export { IconDelete };
export { IconRemove };
