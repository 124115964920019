export const VIEW_TYPES = {
  CHARTS: 'CHARTS',
  TABLES: 'TABLES',
  OVERVIEW: 'OVERVIEW'
};

export const REPORT_TIMESPAN = {
  CURRENT: 'CURRENT',
  LASTMONTH: 'LASTMONTH',
  L3MONTHS: 'L3MONTHS',
  L6MONTHS: 'L6MONTHS',
  L12MONTHS: 'L12MONTHS',
  CURRYEAR: 'CURRYEAR',
  LASTYEAR: 'LASTYEAR',
  CUSTOM: 'CUSTOM'
};
export const REPORT_TIMESPAN_TEXT = {
  CURRENT: 'Current month',
  LASTMONTH: 'Previous month',
  L3MONTHS: 'Last 3 months',
  L6MONTHS: 'Last 6 months',
  L12MONTHS: 'Last 12 months',
  CURRYEAR: 'Current year',
  LASTYEAR: 'Last year',
  CUSTOM: 'Custom range'
};

export const REPORT_TYPES = {
  MONTH: 'month',
  DAY: 'day'
};
export const REPORT_TYPES_TEXT = {
  month: 'Monthly',
  day: 'Daily'
};
