import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Auth from '../common/auth.js';
import { userState } from '../state/user';
import MeltitLogo from '../components/icons/logo.js';

export default function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useRecoilState(userState);

  function onSubmit() {
    if (username !== '' && password !== '') {
      Auth.login({ username, password }).then((data) => {
        setUser(data);
      });
    }
  }

  function handleKeyUp(e){
    if (e.keyCode === 13) {
      onSubmit();
    }
  }
  
  if (user) {
    <Navigate replace to="/" />
  }

  return (
    <div
      className="w-full bg-bgdark pt-32 flex flex-col justify-between"
      style={{ height: '100vh' }}
    >
      <h2 className="max-w-sm mx-auto text-4xl text-center text-white mt-4 mb-20"><MeltitLogo className="w-20" /></h2>
      <div className="max-w-sm mx-auto flex p-6 rounded-lg flex flex-col flex-1">
        <div className="mb-2">
          <input
            className="input w-full"
            type="text"
            placeholder="User Name"
            value={username}
            onChange={(e) => { setUsername(e.target.value); }}
            onKeyUp={handleKeyUp}
          />
        </div>
        <div className="mb-6">
          <input
            className="input w-full"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => { setPassword(e.target.value); }}
            onKeyUp={handleKeyUp}
          />
        </div>
        <div className="text-center">
          <button
            className="inline-block w-24 bg-light text-black font-bold py-2 px-4 rounded-full focus:outline-none"
            onClick={onSubmit}
          >
            Login
          </button>
        </div>
      </div>
      <h3 className="max-w-sm mx-auto text-xl text-center text-white pb-4">
        Meltit Administration Client
      </h3>
    </div>
  );
}
