import React, { useState, useEffect } from 'react';

import { get } from '../common/utils/http.js';
import { baseApi } from '../common/config.js';
import { isEmpty } from '../common/utils/obj.js';

import YieldBoxes from '../components/yield-box/yield-boxes';
import { useRecoilValue } from 'recoil';
import { clientsState } from '../state/clients.js';

function HomePage(props) {
  const clients = useRecoilValue(clientsState);
  const today = new Date();

  const [data, setData] = useState({});

  useEffect(() => {
    const month = today.getMonth();
    const correctMonth = month + 1;
    const year = today.getFullYear();

    const from = `${year}-${correctMonth > 9 ? correctMonth : '0' + correctMonth}-01`;

    get(`${baseApi}/cycles/report?from=${from}`).then((data) => {
      setData(data);
    });
  }, []);

  return (
    <div className="flex-1 p-12 overflow-y-auto">
      <div className="flex flex-col">
        {!isEmpty(data) && <YieldBoxes clients={clients} reportData={data} />}
      </div>
    </div>
  );
}

export default HomePage;
