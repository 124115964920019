import React from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import 'chart.js/auto';

import App from './app';

const el = document.getElementById('app');
const root = createRoot(el);

root.render(
  <RecoilRoot>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </RecoilRoot>
);

if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
  document.body.style.cursor = 'pointer';
}
