import React from 'react';
import { Chart } from 'react-chartjs-2';
import { hexToRgbA } from '../common/utils/colors.js';

export default function ReportCharts(props) {
  const { info } = props;
  return (
    <div className="flex flex-wrap gap-8">
      {info.map((item) => (
          item.separated.map((separation) => {
            const data = item.data[separation.id];

            const chartOptions = {
              scales: {
                yAxes: {
                  y: {
                    beginAtZero: true,
                    stacked: true,
                  },
                  x: {
                    stacked: true,
                  }
                }
              }
            };

            const chartData = {
              labels: data.dates.map((date) => date.date),
              padding: {
                left: 20,
                right: 20,
                top: 0,
                bottom: 0
              },
              datasets: [
                {
                  label: 'Aluminum',
                  backgroundColor: '#fd5c04',
                  data: data.dates.map((item) => item.aluminium)
                },
                {
                  label: 'Residue',
                  backgroundColor: hexToRgbA('#211113', 0.8),
                  data: data.dates.map((item) => item.totalvolume - item.aluminium)
                }
              ]
            };

            return (
              <div className="mb-4 flex-1 w-full max-w-lg" key={separation.id + item.clientName}>
                <h2 className="text-xl mb-2">{separation.name}</h2>
                <h3 className="text-lg">{item.clientName}</h3>
                <div className="mb-6 w-max-md">
                  <Chart type="bar" data={chartData} options={chartOptions} />
                </div>
              </div>
            );
          })
      ))}
    </div>
  );
}
