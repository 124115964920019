import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ClientDetail from './client-detail.js';
import ClientEdit from './client-edit.js';

const Client = function (props) {
  const { client, updateClient } = props;
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const onSave = function (mdl) {
    updateClient(mdl).then(() => {
      setEditMode(false);
    });
  };

  return editMode ? (
    <ClientEdit client={client} onSave={onSave} toggleEditMode={toggleEditMode} />
  ) : (
    <ClientDetail client={client} toggleEditMode={toggleEditMode} />
  );
};
Client.propTypes = {
  client: PropTypes.object,
};

export default Client;
