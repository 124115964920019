/**
 * @extends Error
 */
class ExtendableError extends Error {
  constructor(message, status, details) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.status = status;
    this.details = details;
  }
}

/**
 * Class representing an API error.
 * @extends ExtendableError
 */
class APIError extends ExtendableError {
  /**
   * Creates an API error.
   * @param {string} message - Error message.
   * @param {number} status - HTTP status code of error.
   * @param {array} details - Detailed information
   */
  constructor(message, status = 500, details) {
    super(message, status, details);
  }
}

export default APIError;
