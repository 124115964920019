import { useEffect, useState } from 'react';
import { baseApi } from '../common/config';
import { get, put } from '../common/utils/http';

export default function useMember(memberId) {
  const [member, setMember] = useState(null);

  useEffect(() => {
    get(`${baseApi}/users/${memberId}`).then((data) => {
      setMember(data);
    });
  }, []);

  function updateMember({ name, email }) {
    return put(`${baseApi}/users/${memberId}`, { name, email }).then((data) => {
      setMember(data);
    });
  }

  function updateMemberPassword(password) {
    return put(`${baseApi}/users/${memberId}/password`, { password })
  }

  return {
    member,
    updateMember,
    updateMemberPassword
  };
}
