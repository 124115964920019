import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { stringSort } from '../common/utils/sort.js';

import { IconEdit } from '../components/icons/icons.js';

const getInitials = (name) =>
  name
    .split(' ')
    .map((n) => (n.length > 0 ? n[0] : ''))
    .join('');

class MemberList extends Component {
  static propTypes = {
    members: PropTypes.array,
    onMemberSelect: PropTypes.func,
  };

  render() {
    const { members, onMemberSelect } = this.props;

    return (
      <div className="user-wrapper w-full max-w-main">
        <Link className="block button mb-8 w-32" to="/members/new">
          + New User
        </Link>
        <table className="table-auto border-collapse border border-bordercl w-full">
          <thead className="bg-bgdark text-light h-12 text-1">
            <tr className="border-b-2">
              <th className="px-4 py-2 text-normal">Avatar</th>
              <th className="px-4 py-2 text-normal">Name</th>
              <th className="px-4 py-2 text-normal">Role</th>
              <th className="px-4 py-2 text-normal">Username</th>
              <th className="px-4 py-2 text-normal">Active</th>
              <th className="px-4 py-2 text-normal"></th>
            </tr>
          </thead>
          <tbody>
            {members.sort(stringSort('name')).map((member, i) => {
              const className = classnames('h-12 border border-light', {
                'bg-bglight': i % 2,
              });
              return (
                <tr key={member.id} className={className}>
                  <td className="px-4 py-2">
                    {member.avatar ? (
                      <img className="w-8 h-8 rounded-full" src={member.avatar} />
                    ) : (
                      <div className="initials-avatar mr-2 w-8 h-8 text-xs leading-8">
                        {getInitials(member.name)}
                      </div>
                    )}
                  </td>
                  <td className="px-4 py-2">{member.name}</td>
                  <td className="px-4 py-2">{member.role}</td>
                  <td className="px-4 py-2">{member.username}</td>
                  <td className="px-4 py-2">{member.active ? 'Yes' : 'No'}</td>
                  <td className="px-4 py-2">
                    <Link to={`/members/${member.id}`}>
                      <IconEdit className="w-6 h-6" />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default MemberList;
