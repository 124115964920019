import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Client = function (props) {
  const { client, toggleEditMode } = props;
  const [detailView, toggleDetailView] = useState(false);

  return (
    <div className="flex flex-col max-w-3xl border border-light rounded-lg p-4 bg-whiteish mb-4">
      <div className="flex justify-between mb-2">
        <h2 className="text-xl">{client.name}</h2>
      </div>
      <div className="flex mb-4">
        <label className="block mr-8">
          <span className="block text-light">Code</span>
          {client.code}
        </label>
        <label className="block mr-8">
          <span className="block text-light">Color</span>
          <div className="w-6 h-6" style={{ backgroundColor: client.config.color }}></div>
        </label>
        <label className="block mr-8">
          <span className="block text-light">Yield Goal (%)</span>
          {client.config.yieldgoal}
        </label>
        <div className="flex-1"></div>
        <button className="button py-0 leading-none text-sm h-10 mr-6" onClick={toggleEditMode}>
          Edit Client
        </button>
      </div>
      {detailView && (
        <>
          <hr className="border-light mb-4" />
          <div className="flex">
            <div className="flex flex-col flex-1 mr-2">
              <h3 className="text-lg mb-2">Client Config</h3>
              {client.config.containers && (
                <p className="border px-2 py-1 border-light mb-2 rounded bg-white">Use Containers</p>
              )}
              {client.config.allowcontainercombine && (
                <p className="border px-2 py-1 border-light mb-2 rounded bg-white">Allow container to combine</p>
              )}
              {client.config.salt && <p className="border px-2 py-1 border-light mb-2 rounded bg-white">Use Salt</p>}
              {client.config.notes && <p className="border px-2 py-1 border-light mb-2 rounded bg-white">Use notes</p>}
            </div>
            <div className="flex flex-col flex-1 ml-2">
              <h3 className="text-lg mb-2">Materials</h3>
              {client.config.materials.map((mat) => {
                return (
                  <p key={mat.id} className="border px-2 py-1 border-light mb-2 rounded bg-white">
                    <span className="inline-block w-6">{mat.separate && '★'}</span>
                    {mat.name}
                  </p>
                );
              })}
            </div>
          </div>
        </>
      )}
      <button className="text-lg" onClick={() => toggleDetailView(!detailView)}>
        {detailView ? '⌃' : '⌄'}
      </button>
    </div>
  );
};
Client.propTypes = {
  client: PropTypes.object
};

export default Client;
