import fetch from 'isomorphic-fetch';
import * as Auth from '../auth';
import APIError from './error.js';

export const getAuthHeader = () => {
  if (Auth.user.isAuth) {
    const token = Auth.getToken();

    return {
      Authorization: `Bearer ${token}`
    };
  }

  return {};
};

export const getHeaders = () => {
  return {
    'Content-Type': 'application/json; charset=utf-8',
    ...getAuthHeader()
  };
};

export const post = (url, data, options) => {
  let postData = data;
  if (typeof data !== 'string') {
    postData = JSON.stringify(data);
  }

  return fetch(url, {
    method: 'POST',
    headers: getHeaders(),
    body: postData,
    ...options
  }).then((response) => {
    if (!response.ok) {
      if (response.json) {
        return response.json().then((err) => {
          throw new APIError(err.message, response.status, err.details);
        });
      }
      throw response.body;
    }

    return response.json();
  });
};

export const put = (url, data, options) => {
  let postData = data;
  if (typeof data !== 'string') {
    postData = JSON.stringify(data);
  }

  return fetch(url, {
    method: 'PUT',
    headers: getHeaders(),
    body: postData,
    ...options
  }).then((response) => {
    if (!response.ok) {
      if (response.json) {
        return response.json().then((err) => {
          throw new APIError(err.message, response.status, err.details);
        });
      }
      throw response.body;
    }

    return response.json();
  });
};

export const patch = (url, data, options) => {
  let postData = data;
  if (typeof data !== 'string') {
    postData = JSON.stringify(data);
  }

  return fetch(url, {
    method: 'PATCH',
    headers: getHeaders(),
    body: postData,
    ...options
  }).then((response) => {
    if (!response.ok) {
      throw response.body;
    }

    return response.json().catch(() => {
      // No json;
      return response.body;
    });
  });
};

export const del = (url, options) => {
  return fetch(url, {
    method: 'DELETE',
    headers: getHeaders(),
    ...options
  }).then((response) => {
    if (!response.ok) {
      throw response.body;
    }

    return response.json().catch(() => {
      // No json;
      return response.body;
    });
  });
};

export const getFile = (url) => {
  return fetch(url, {
    headers: getHeaders()
  }).then((response) => {
    return response.blob();
  });
};

export const get = (url) => {
  return fetch(url, {
    headers: getHeaders()
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((data) => {
        throw data;
      });
    }
    return response.json();
  });
};
