import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { get, getFile } from '../common/utils/http.js';
import { baseApi } from '../common/config.js';
import { toSimpleDate } from '../common/utils/date.js';
import TakeoutItem from './takeout-item.js';
import TakeoutHeader from './takeout-header.js';
import { VIEWS } from './enums.js';
import { clientsState } from '../state/clients.js';

function Takeout() {
  const clients = useRecoilValue(clientsState);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    get(`${baseApi}/export`).then((data) => {
      setTemplates(data);
    });
  }, []);

  function downloadExport({ id, from, to }) {
    let anchor = document.createElement('a');
    document.body.appendChild(anchor);
    const fileUrl = `${baseApi}/export/${id}/download?from=${toSimpleDate(from)}&to=${toSimpleDate(to)}`;

    getFile(fileUrl)
      .then((blobFile) => {
        let objectUrl = window.URL.createObjectURL(blobFile);

        anchor.href = objectUrl;
        anchor.download = 'data.xls';
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      })
      .then(() => {
        anchor.remove();
      });
  }

  return (
    <div className="flex-1 overflow-y-auto">
      <TakeoutHeader view={VIEWS.TEMPLATE} />
      <div className="export-wrapper p-12 flex flex-row flex-wrap">
        {templates.map((template) => {
          const client = clients.find((cl) => cl._id === template.clientId);
          return <TakeoutItem key={template._id} template={template} client={client} downloadExport={downloadExport} />;
        })}
      </div>
    </div>
  );
}

export default Takeout;
