import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { daysInMonth, toSimpleDate, getTomorrow, getStartOfMonth } from '../common/utils/date.js';
import { prettyNumber } from '../common/utils/numbers.js';
import ExcellentExport from '../common/utils/excelentexport';

export default function ReportList(props) {
  const { info } = props;

  function downloadTable(e, rndId) {
    return ExcellentExport.excel(e.currentTarget, rndId, 'Report');
  }

  return (
    <div>
      {info.map((item) => (
        <div className="mb-4" key={item.clientName}>
          <h2 className="text-xl mb-2">{item.clientName}</h2>
          {item.separated.map((separation) => {
            const data = item.data[separation.id];
            const rndId = `data-${Math.random()}`;
            return (
              <div className="mb-8" key={separation.id}>
                <div className="flex justify-between">
                  <h3 className="text-lg">{separation.name}</h3>
                  <p>Yield: {((data.totals.aluminium / data.totals.volume) * 100).toFixed(1)} %</p>
                </div>
                <table id={rndId} className="table-auto border-collapse border border-bordercl w-full mb-2">
                  <thead className="bg-bgdark text-light h-12 text-1">
                    <tr>
                      <th>Date</th>
                      <th>Volume</th>
                      {item.useSalt && <th>Salt</th>}
                      <th>Oil</th>
                      <th>Oxygen</th>
                      <th>Aluminium</th>
                      <th>Yield</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.dates.map((row, i) => {
                      const className = classnames('h-12 border border-light', {
                        'bg-bglight': i % 2
                      });
                      return (
                        <tr className={className} key={row.date.toString()}>
                          <th className="">{row.date}</th>
                          <td className="text-right px-2">{prettyNumber(row.volume)}kg</td>
                          {item.useSalt && <td className="text-right px-2">{prettyNumber(row.salt)}kg</td>}
                          <td className="text-right px-2">{prettyNumber(row.oil)}l</td>
                          <td className="text-right px-2">
                            {prettyNumber(row.oxygen)}Nm<sup>3</sup>
                          </td>
                          <td className="text-right px-2">{prettyNumber(row.aluminium)}kg</td>
                          <td className="text-right px-2 font-semibold">{(row.yield * 100).toFixed(1)} %</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <a
                  href="#"
                  download={`${item.clientName}-${separation.id}.xls`}
                  onClick={(e) => downloadTable(e, rndId)}
                >
                  Download
                </a>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}
