import React, { useState, useEffect } from 'react';
import { get, post, del } from '../common/utils/http.js';
import { baseApi } from '../common/config.js';
import { IconDelete, IconCheck, IconCopy } from '../components/icons/icons.js';
import { getSimpleDate } from '../common/utils/date.js';

function getEmptyAuthorisation() {
  return {
    name: '',
    description: '',
    subjects: [],
  };
}

const Authorisations = () => {
  const [authorisations, setAuthorisations] = useState([]);
  const [newAuthOpen, setNewAuthOpen] = useState(false);
  const [newAuthorisation, setNewAuthorisation] = useState(getEmptyAuthorisation());
  const [copyCheck, setCopyCheck] = useState('');

  useEffect(() => {
    get(`${baseApi}/company/authorisations`).then((data) => {
      setAuthorisations(data);
    });
  }, []);

  function onNewAuthChange(e) {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'subjects') {
      if (newAuthorisation.subjects.includes(value)) {
        value = newAuthorisation.subjects.filter((auth) => auth !== value);
      } else {
        value = [...newAuthorisation.subjects, value];
      }
    }

    setNewAuthorisation({
      ...newAuthorisation,
      [name]: value,
    });
  }

  function saveAuthorisation() {
    const auth = { ...newAuthorisation };
    if (auth.name === '' || auth.description === '' || auth.subjects.length === 0) {
      //Failed
      console.log('Failed to create auth', auth);
      return;
    }

    post(`${baseApi}/company/authorisations`, auth).then((data) => {
      setAuthorisations([...authorisations, data]);
      setNewAuthorisation(getEmptyAuthorisation());
      setNewAuthOpen(false);
    });
  }

  function toggleNewAuthOpen() {
    setNewAuthOpen(!newAuthOpen);
  }

  function copyAuthCode(e) {
    e.preventDefault();
    const input = e.currentTarget.parentElement.querySelector('input');
    const authId = e.currentTarget.getAttribute('data-auth-id');

    input.select();
    input.setSelectionRange(0, 99999);

    document.execCommand('copy');

    setCopyCheck(authId);
  }

  useEffect(() => {
    let isCanceled = false;
    setTimeout(() => {
      if (!isCanceled) {
        setCopyCheck('');
      }
    }, 3000);

    return () => {
      isCanceled = true;
    };
  }, [copyCheck]);

  function removeAuthToken(authId) {
    del(`${baseApi}/company/authorisations/${authId}`)
      .then((data) => {
        const newAuths = authorisations.filter((auth) => auth._id !== authId);
        setAuthorisations(newAuths);
      })
      .catch((ex) => {
        console.log(ex);
      });
  }

  return (
    <div className="cycles-wrapper">
      <h2 className="text-2xl mb-4">API Authorisations</h2>
      <button className="button mb-4" onClick={() => toggleNewAuthOpen(!newAuthOpen)}>
        + Add API Authorisation
      </button>
      {newAuthOpen && (
        <div className="block mb-8 p-4 border rounded">
          <h3 className="text-xl">Add API Authorisation</h3>
          <div className="mb-2">
            <label className="text-dark text-sm">Name</label>
            <input
              type="text"
              className="input w-full"
              value={newAuthorisation.name}
              name="name"
              onChange={onNewAuthChange}
            />
          </div>
          <div className="mb-2">
            <label className="text-dark text-sm">Description</label>
            <input
              type="text"
              className="input w-full"
              value={newAuthorisation.description}
              name="description"
              onChange={onNewAuthChange}
            />
          </div>
          <div className="mb-4">
            <label className="text-dark text-sm">Subjects</label>
            <select
              className="w-full p-2 border border-light rounded"
              multiple
              value={newAuthorisation.subjects}
              name="subjects"
              onChange={onNewAuthChange}
            >
              <option value="REPORTS">Reports</option>
              <option value="USERS">Users</option>
            </select>
          </div>
          <div className="flex justify-between">
            <button className="button" onClick={saveAuthorisation}>
              Save
            </button>
            <button
              className="inline-block align-baseline font-semibold text-sm text-light"
              onClick={toggleNewAuthOpen}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      <table className="table-auto border-collapse border-gray-500  w-full">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Active</th>
            <th>Created date</th>
            <th colSpan="2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {authorisations.map((authorisation, i) => (
            <tr key={authorisation._id} className={i % 2 ? 'bg-gray-100' : ''}>
              <td className="px-4 py-2">{authorisation.name}</td>
              <td className="px-4 py-2">{authorisation.description}</td>
              <td className="px-4 py-2">{authorisation.active ? 'True' : 'False'}</td>
              <td className="px-4 py-2">{getSimpleDate(new Date(authorisation.createdAt))}</td>
              <td>
                <input type="text" className="w-0 h-0" readOnly value={authorisation.code} />
                <button onClick={copyAuthCode} data-auth-id={authorisation._id}>
                  {copyCheck === authorisation._id ? (
                    <IconCheck className="w-6 h-6" />
                  ) : (
                    <IconCopy className="w-6 h-6" />
                  )}
                </button>
              </td>
              <td>
                <button onClick={() => removeAuthToken(authorisation._id)}>
                  <IconDelete className="w-6 h-6" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Authorisations;
