import React, { Component } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import MemberEdit from './member-edit.js';
import MemberPassword from './member-password.js';
import useMember from './use-member.js';

export default function MemberDetails() {
  const { memberId } = useParams();

  const { member, updateMember, updateMemberPassword } = useMember(memberId);
  
  if(member === null) {
    return <div>Loading...</div>
  }

  return (
    <div className="flex-1 p-12 overflow-y-auto">
      <div className="user-details max-w-lg">
        <MemberEdit memberId={memberId} member={member} updateMember={updateMember} />
        <MemberPassword memberId={memberId} updateMemberPassword={updateMemberPassword} />
      </div>
    </div>
  );
}
