import { useEffect, useState } from "react";
import { baseApi } from "../common/config";
import { get } from "../common/utils/http";


export default function useMembers(){
  const [members, setMembers] = useState([]);
  
  useEffect(() => {
    get(`${baseApi}/users`)
      .then((data) => {
        setMembers(data);
      });
  }, [])


  return {
    members
  }
}