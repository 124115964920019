import React from 'react';

export default function IconShow({ className = '' } = {}) {
  return (
    <svg className={className} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M16,25a10.59,10.59,0,0,1-7.53-3.12L3.29,16.71a1,1,0,0,1,0-1.42l5.18-5.17a10.65,10.65,0,0,1,15.06,0l5.18,5.17a1,1,0,0,1,0,1.42l-5.18,5.17A10.59,10.59,0,0,1,16,25ZM5.41,16l4.47,4.47a8.67,8.67,0,0,0,12.24,0L26.59,16l-4.47-4.47a8.67,8.67,0,0,0-12.24,0Z"
      />
      <path
        fill="currentColor"
        d="M16,20a4,4,0,1,1,4-4A4,4,0,0,1,16,20Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,16,14Z"
      />
    </svg>
  );
}
