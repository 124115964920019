import React, { useState } from 'react';
import { REPORT_TIMESPAN, REPORT_TIMESPAN_TEXT, REPORT_TYPES, REPORT_TYPES_TEXT } from './enums.js';

function ReportTypeSelect(params) {
  const { setReportTimespan, reportTimespan } = params;
  const [isOpen, setIsOpen] = useState(false);

  function onSelectDay(day) {
    setIsOpen(false);
    setDate(day);
  }

  return (
    <div className="relative inline mr-12">
      <span
        className="bg-transparent appearance-none py-1 cursor-pointer text-primary font-semi"
        onClick={() => setIsOpen(!isOpen)}
      >
        {REPORT_TIMESPAN_TEXT[reportTimespan]} <span className="ml-4">&#9660;</span>
      </span>
      {isOpen && (
        <div className="absolute top-full left-0 bg-white shadow-md z-10">
          <ul>
            <li
              className="cursor-pointer px-6 py-2 w-48 hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setReportTimespan(REPORT_TIMESPAN.CURRENT);
              }}
            >
              {REPORT_TIMESPAN_TEXT.CURRENT}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48 border-b border-bgdark bg-bglight hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setReportTimespan(REPORT_TIMESPAN.LASTMONTH);
              }}
            >
              {REPORT_TIMESPAN_TEXT.LASTMONTH}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48 hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setReportTimespan(REPORT_TIMESPAN.L3MONTHS);
              }}
            >
              {REPORT_TIMESPAN_TEXT.L3MONTHS}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48 border-b border-bglight  bg-bglight hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setReportTimespan(REPORT_TIMESPAN.L6MONTHS);
              }}
            >
              {REPORT_TIMESPAN_TEXT.L6MONTHS}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48 border-b border-bgdark hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setReportTimespan(REPORT_TIMESPAN.L12MONTHS);
              }}
            >
              {REPORT_TIMESPAN_TEXT.L12MONTHS}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48 border-b border-bglight  bg-bglight hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setReportTimespan(REPORT_TIMESPAN.CURRYEAR);
              }}
            >
              {REPORT_TIMESPAN_TEXT.CURRYEAR}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48 border-b border-bgdark hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setReportTimespan(REPORT_TIMESPAN.LASTYEAR);
              }}
            >
              {REPORT_TIMESPAN_TEXT.LASTYEAR}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48  bg-bglight hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setReportTimespan(REPORT_TIMESPAN.CUSTOM);
              }}
            >
              {REPORT_TIMESPAN_TEXT.CUSTOM}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default ReportTypeSelect;
