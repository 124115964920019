import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      <div className="flex-1 p-12 overflow-y-auto">
        <div className="home-wrapper">
          <h2>404 Not Found</h2>
        </div>
      </div>
    );
  }
}

export default NotFound;
