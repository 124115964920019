import React from 'react';

export default function IconDownload({ className = '' } = {}) {
  return (
    <svg className={className} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M16,29a1,1,0,0,1-.71-.29l-9-9a1,1,0,0,1,1.42-1.42L16,26.59l8.29-8.3a1,1,0,0,1,1.42,1.42l-9,9A1,1,0,0,1,16,29Z"
      />
      <path fill="currentColor" d="M16,29a1,1,0,0,1-1-1V4a1,1,0,0,1,2,0V28A1,1,0,0,1,16,29Z" />
    </svg>
  );
}
