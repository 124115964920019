import React, { useState } from 'react';
import { PICKER_TIMESPAN, PICKER_TIMESPAN_TEXT } from '../common/enums.js';

function DeliveryTypeSelect(params) {
  const { setTypeTimespan, typeTimespan } = params;
  const [isOpen, setIsOpen] = useState(false);

  function onSelectDay(day) {
    setIsOpen(false);
    setDate(day);
  }

  return (
    <div className="relative inline mr-12">
      <span
        className="bg-transparent appearance-none py-1 cursor-pointer text-primary font-semi"
        onClick={() => setIsOpen(!isOpen)}
      >
        {PICKER_TIMESPAN_TEXT[typeTimespan]} <span className="ml-4">&#9660;</span>
      </span>
      {isOpen && (
        <div className="absolute top-full left-0 bg-white shadow-md z-10">
          <ul>
            <li
              className="cursor-pointer px-6 py-2 w-48 hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setTypeTimespan(PICKER_TIMESPAN.CURRENT);
              }}
            >
              {PICKER_TIMESPAN_TEXT.CURRENT}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48 border-b border-bgdark bg-bglight hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setTypeTimespan(PICKER_TIMESPAN.LASTMONTH);
              }}
            >
              {PICKER_TIMESPAN_TEXT.LASTMONTH}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48 hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setTypeTimespan(PICKER_TIMESPAN.L3MONTHS);
              }}
            >
              {PICKER_TIMESPAN_TEXT.L3MONTHS}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48 border-b border-bglight bg-bglight hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setTypeTimespan(PICKER_TIMESPAN.L6MONTHS);
              }}
            >
              {PICKER_TIMESPAN_TEXT.L6MONTHS}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48 border-b border-bgdark hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setTypeTimespan(PICKER_TIMESPAN.L12MONTHS);
              }}
            >
              {PICKER_TIMESPAN_TEXT.L12MONTHS}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48 border-b border-bglight hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setTypeTimespan(PICKER_TIMESPAN.CURRYEAR);
              }}
            >
              {PICKER_TIMESPAN_TEXT.CURRYEAR}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48 border-b border-bgdark hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setTypeTimespan(PICKER_TIMESPAN.LASTYEAR);
              }}
            >
              {PICKER_TIMESPAN_TEXT.LASTYEAR}
            </li>
            <li
              className="cursor-pointer px-6 py-2 w-48  bg-bglight hover:bg-bgdark hover:text-white"
              onClick={() => {
                setIsOpen(false);
                setTypeTimespan(PICKER_TIMESPAN.CUSTOM);
              }}
            >
              {PICKER_TIMESPAN_TEXT.CUSTOM}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default DeliveryTypeSelect;
