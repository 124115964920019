import React from 'react';

export default function IconSettings({ className = '' } = {}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <path
        fill="currentColor"
        d="M16.99,7.599l-1.808-.604-.391-.947.854-1.7a1.4827,1.4827,0,0,0-1.986-1.992l-1.706.853-.946-.393-.601-1.804a1.4827,1.4827,0,0,0-2.812,0l-.6,1.802-.946.392L4.351,2.357A1.4834,1.4834,0,0,0,2.362,4.346l.849,1.7-.391.946L1.01,7.6a1.4827,1.4827,0,0,0,.004,2.812l1.803.599.393.945-.85,1.708a1.4827,1.4827,0,0,0,1.992,1.985l1.694-.85.947.39.601,1.801a1.4827,1.4827,0,0,0,2.812-.004l.597-1.799.946-.392,1.701.849a1.484,1.484,0,0,0,1.988-1.991l-.85-1.696.393-.947,1.804-.598A1.4782,1.4782,0,0,0,18,9.007V9.003A1.4791,1.4791,0,0,0,16.99,7.599ZM17,9.007a.48.48,0,0,1-.329.456l-2.022.67a.4979.4979,0,0,0-.304.283l-.569,1.369a.5013.5013,0,0,0,.015.415l.953,1.901a.4822.4822,0,0,1-.093.558.4756.4756,0,0,1-.554.09l-1.906-.951a.5.5,0,0,0-.416-.015l-1.366.569a.4979.4979,0,0,0-.283.304l-.669,2.016a.5032.5032,0,0,1-.915.001l-.673-2.017a.4979.4979,0,0,0-.284-.303l-1.369-.565a.5082.5082,0,0,0-.191-.038.5018.5018,0,0,0-.224.053l-1.897.953a.485.485,0,0,1-.559-.093.4788.4788,0,0,1-.09-.553l.952-1.913a.4974.4974,0,0,0,.014-.415l-.569-1.366a.4965.4965,0,0,0-.304-.282L1.329,9.463a.4835.4835,0,0,1-.001-.916l2.026-.68a.499.499,0,0,0,.304-.282l.565-1.368a.5029.5029,0,0,0-.015-.414L3.256,3.898a.4807.4807,0,0,1,.093-.556.4756.4756,0,0,1,.555-.09l1.901.951a.501.501,0,0,0,.415.015L7.588,3.65a.4989.4989,0,0,0,.283-.303l.672-2.019a.5022.5022,0,0,1,.915,0l.672,2.021a.4989.4989,0,0,0,.283.303l1.368.568a.5051.5051,0,0,0,.415-.015l1.91-.954a.4827.4827,0,0,1,.646.647l-.957,1.905a.4975.4975,0,0,0-.015.415l.564,1.369a.5022.5022,0,0,0,.304.284l2.024.677A.4774.4774,0,0,1,17,9.003Z"
      />
      <path
        fill="currentColor"
        d="M9,5.4092A3.591,3.591,0,1,0,12.5913,9,3.5945,3.5945,0,0,0,9,5.4092Zm0,6.182A2.591,2.591,0,1,1,11.5913,9,2.5942,2.5942,0,0,1,9,11.5912Z"
      />
    </svg>
  );
}
