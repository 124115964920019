import React from 'react';
import MembersList from './list.js';
import useMembers from './use-members.js';

function Members() {
  const { members } = useMembers()
  
  return (
    <div className="flex-1 p-12 overflow-y-auto">
      <MembersList members={members} />
    </div>
  );
}

export default Members;
