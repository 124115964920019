import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { userState } from '../state/user.js';

import CompanySettings from './company.js';
import CompanyLotnumber from './lotnumber.js';
import Authorisations from './authorisations.js';

function Settings() {
  const user = useRecoilValue(userState);
  return (
    <div className="flex-1 p-12 overflow-y-auto">
      <div className="settings max-w-lg">
        <CompanySettings user={user} />
        <hr className="border-light mb-8" />
        <CompanyLotnumber />
        <hr className="border-light mb-8" />
        <Authorisations />
      </div>
    </div>
  );
}

export default Settings;
