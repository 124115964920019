/**
 * Simplified ExcellentExport 1.0.0
 * A server side Javascript export to Excel.
 *
 * @originalauthor: Jordi Burgos (jordiburgos@gmail.com)
 * @originalurl: https://github.com/jmaister/excellentexport
 *
 */
/*jslint browser: true, bitwise: true, vars: true, white: true */
/*global define, exports, module */

const ExcellentExport = (function () {
  const version = '1.0.0';
  const template = {
    excel:
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
  };
  let csvDelimiter = ',';
  let csvNewLine = '\r\n';

  const format = function (s, c) {
    return s.replace(new RegExp('{(\\w+)}', 'g'), function (m, p) {
      return c[p];
    });
  };

  const fixCSVField = function (value) {
    let fixedValue = value;
    const addQuotes = value.indexOf(csvDelimiter) !== -1 || value.indexOf('\r') !== -1 || value.indexOf('\n') !== -1;
    const replaceDoubleQuotes = value.indexOf('"') !== -1;

    if (replaceDoubleQuotes) {
      fixedValue = fixedValue.replace(/"/g, '""');
    }
    if (addQuotes || replaceDoubleQuotes) {
      fixedValue = '"' + fixedValue + '"';
    }

    return fixedValue;
  };

  const convertArrayToCSV = function (table) {
    return table.map((tr) => tr.map((td) => fixCSVField(td.trim())).join(csvDelimiter)).join(csvNewLine);
  };

  const convertArrayToTable = function (table) {
    return table.map((tr) => `<tr>${tr.map((td) => `<td>${td}</td>`).join('')}</tr>`).join('');
  };

  return {
    version: function () {
      return version;
    },
    convert: function (data, options = {}) {
      if (data === undefined) {
        throw new Error('Table is missing');
      }
      const name = options.name || 'export';

      if (options.type === 'csv') {
        const csvData = '\uFEFF' + convertArrayToCSV(data);

        return { data: csvData, type: 'application/csv', name: `${name}.csv` };
      } else {
        const table = convertArrayToTable(data);
        const ctx = { worksheet: name, table: table };

        return { data: format(template.excel, ctx), type: 'application/vnd.ms-excel', name: `${name}.xls` };
      }
    }
  };
})();

module.exports = ExcellentExport;
