import React, { useRef } from 'react';
import { Chart } from 'react-chartjs-2';
import html2canvas from 'html2canvas';

import { formatPercent, formatWeight } from '../../common/utils/units.js';
import { prettyNumber } from '../../common/utils/numbers.js';
import { isEmpty } from '../../common/utils/obj.js';
import { IconDownload } from '../icons/icons.js';

function YieldBox({ data }) {
  const wrapperRef = useRef();

  const chartOptions = {
    responsive: false,
    legend: {
      position: 'left',
      labels: {
        fontFamily: 'opensans-semi',
        fontColor: '#000',
        boxWidth: 20,
        padding: 12
      }
    }
  };

  if (isEmpty(data)) {
    return <div></div>;
  }

  function downloadCharts(){
    const div = wrapperRef.current;
    html2canvas(div)
      .then((canvas) => {
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');
     
        link.href = data;
        link.download = 'downloaded-image.jpg';
     
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  return (
    <div
      className="w-full max-w-lg border border-light rounded-xl p-2 bg-bgdark flex flex-col"
      key={`${data.clientId}-${data.materialType}`}
      ref={wrapperRef}
    >
      <div className="flex justify-between">
        <h2 className="text-xl pb-2 text-white">{data.materialType}</h2>
        <span className="text-primary">{data.clientName}</span>
      </div>
      <div className="flex-1 flex flex-col rounded-lg overflow-hidden bg-whiteish">
        <div className="flex-1">
          <table className="table-auto w-full text-left table-oddlines">
            <tbody>
              <tr className="h-12">
                <th className="px-2 font-normal">Total volume</th>
                <td className="text-right px-2 font-semi">{formatWeight(data.workData.volume)}</td>
              </tr>
              <tr className="h-12">
                <th className="px-2 font-normal">Yield</th>
                <td className="text-right px-2 font-semi">
                  {formatPercent(data.workData.aluminium / data.workData.volume)}
                </td>
              </tr>
              <tr className="h-12">
                <th className="px-2 font-normal">Aluminium</th>
                <td className="text-right px-2 font-semi">{formatWeight(data.workData.aluminium)}</td>
              </tr>
              {data.useSalt && (
                <tr className="h-12">
                  <th className="px-2 font-normal">Salt</th>
                  <td className="text-right px-2 font-semi">{formatWeight(data.workData.salt)}</td>
                </tr>
              )}
              <tr className="h-12">
                <th className="px-2 font-normal">Residue</th>
                <td className="text-right px-2 font-semi">
                  {formatWeight(data.workData.residue)}
                </td>
              </tr>
              <tr className="h-12">
                <th className="px-2 font-normal">Oil</th>
                <td className="text-right px-2 font-semi">{prettyNumber(data.workData.oil)} l</td>
              </tr>
              <tr className="h-12">
                <th className="px-2 font-normal">Oxygen</th>
                <td className="text-right px-2 font-semi">
                  {prettyNumber(data.workData.oxygen)} Nm<sup>3</sup>
                </td>
              </tr>
              <tr className="h-12">
                <th className="px-2 font-normal">Lots</th>
                <td className="text-right px-2 font-semi">{data.workData.lots}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="p-4 flex-0 bg-bglight align-end">
          <Chart type="doughnut" data={data} options={chartOptions} height={120} />
          <button onClick={downloadCharts}> <IconDownload className="w-6 h-6 mr-2 inline" />Download</button>
        </div>
      </div>
    </div>
  );
}

export default YieldBox;
