import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { post, put } from '../common/utils/http.js';
import { baseApi } from '../common/config.js';
import { immutableUpdate } from '../common/utils/obj.js';
import { IconRemove } from '../components/icons/icons.js';
import { useNavigate } from 'react-router-dom';

import InputField from '../components/fields/input.js';
import { IconCheck } from '../components/icons/icons.js';

const CycleEdit = (params) => {
  const { cycleId, cycle, client } = params;
  const navigate = useNavigate();

  const [model, setModel] = useState(cycle);
  const [isSaved, setIsSaved] = useState(false);
  const [isDeleted, setDeleted] = useState(false);

  function onValueChange(e) {
    const target = e.target;
    const name = e.target.name;
    const isNumber = target.getAttribute('data-value-type') === 'number';
    const isCheckbox = target.getAttribute('data-value-type') === 'checkbox';

    const value = isNumber ? Number(target.value) : isCheckbox ? target.checked : target.value;

    const newCycle = immutableUpdate(model, name, value);

    setModel(newCycle);
  }

  function onDateChange(e) {
    const target = e.currentTarget;

    const date = new Date(model.createdAt);
    const isTime = target.getAttribute('data-value-type') === 'time';
    const isDate = target.getAttribute('data-value-type') === 'date';

    if (isTime) {
      const timeStr = target.value.split(':');
      date.setHours(timeStr[0]);
      date.setMinutes(timeStr[1]);
    }
    if (isDate) {
      const dateStr = target.value.split('-');
      date.setFullYear(dateStr[0]);
      date.setMonth(Number(dateStr[1]) - 1);
      date.setDate(dateStr[2]);
    }

    setModel({
      ...model,
      createdAt: date.toISOString()
    });
  }

  const onRemove = function (type, number) {
    setModel({
      ...model,
      data: {
        ...model.data,
        [type]: model.data[type].filter((it, i) => i !== number)
      }
    });
  };

  const onAddAluminium = function () {
    setModel({
      ...model,
      data: {
        ...model.data,
        aluminium: [...model.data.aluminium, 0]
      }
    });
  };

  const onAddDross = function () {
    const drossMdl = { weight: 0 };
    if (client.config.containers) {
      drossMdl.container = '';
    }

    setModel({
      ...model,
      data: {
        ...model.data,
        dross: [...model.data.dross, drossMdl]
      }
    });
  };

  const onSave = function () {
    const mdl = {
      state: model.state,
      createdAt: model.createdAt,
      data: {
        lotnumber: model.data.lotnumber,
        dross: model.data.dross,
        salt: model.data.salt,
        material: model.data.material,
        oxygen: model.data.oxygen,
        oil: model.data.oil,
        notes: model.data.notes,
        cycletime: model.data.cycletime,
        aluminium: model.data.aluminium
      }
    };

    if (!cycleId) {
      mdl.clientId = client._id;
      post(`${baseApi}/cycles/admin/new`, mdl).then((data) => {
        navigate(`/cycles/${data._id}`);
      });
    } else {
      put(`${baseApi}/cycles/admin/${cycleId}`, mdl)
        .then((data) => {
          setModel(data);

          setIsSaved(true);
        })
        .catch((ex) => {
          console.log(ex);
        });
    }
  };

  const formatDate = function (dateStr) {
    const date = new Date(dateStr);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  };

  const formatTime = function (dateStr) {
    const date = new Date(dateStr);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
  };

  if (model._id === 0 || client._id === 0) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <div className="flex w-full justify-between mb-8">
        <div className="flex flex-col flex-1 mr-4">
          <div className="flex flex-row justify-between mb-8">
            <InputField
              label="Lotnumber"
              type="text"
              className="mr-4"
              pattern="[0-9]*"
              dataValueType="number"
              name="data.lotnumber"
              onChange={onValueChange}
              disabled={isDeleted}
              value={model.data.lotnumber}
            />
            <InputField
              label="Date"
              type="date"
              className="mr-4"
              dataValueType="date"
              name="createdAt"
              onChange={onDateChange}
              disabled={isDeleted}
              required
              value={formatDate(model.createdAt)}
            />
            <InputField
              label="Time"
              type="time"
              dataValueType="time"
              name="createdAt"
              onChange={onDateChange}
              disabled={isDeleted}
              required
              value={formatTime(model.createdAt)}
            />
          </div>
          {model.data.dross.map((dross, i) => (
            <div key={i} className="mb-4 flex flex-row justify-between">
              {client.config.containers && (
                <InputField
                  label="Container name"
                  type="text"
                  className="mr-2"
                  name={`data.dross[${i}].container`}
                  onChange={onValueChange}
                  disabled={isDeleted}
                  value={dross.container}
                />
              )}
              <InputField
                label="Input volume"
                type="text"
                className="mr-2"
                pattern="[0-9]*"
                dataValueType="number"
                name={`data.dross[${i}].weight`}
                onChange={onValueChange}
                disabled={isDeleted}
                value={dross.weight}
              />
              <div className="pt-8 w-6">
                {i > 0 && (
                  <button className="border rounded right-0 top-0 w-6 h-6" onClick={() => onRemove('dross', i)}>
                    <IconRemove className="w-6 h-6" />
                  </button>
                )}
              </div>
            </div>
          ))}
          {model.data.dross.length === 1 && client.config.allowcontainercombine && (
            <div className="mb-4">
              <button className="button" onClick={onAddDross}>
                + Add container
              </button>
            </div>
          )}
          <div className="mb-4">
            <label className="block text-dark text-sm mb-2" htmlFor="input-material">
              Material
            </label>
            <select
              className="input bg-white w-full"
              id="input-material"
              name="data.material"
              onChange={onValueChange}
              disabled={isDeleted}
              value={model.data.material}
            >
              {model.data.material === '' && <option value="">Select material</option>}
              {client.config.materials.map((mat) => (
                <option key={mat.id} value={mat.id}>
                  {mat.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-row justify-between mb-4">
            <InputField
              label="Oxygen"
              type="text"
              className="mr-4"
              pattern="[0-9]*"
              dataValueType="number"
              name="data.oxygen"
              onChange={onValueChange}
              disabled={isDeleted}
              value={model.data.oxygen}
            />
            <InputField
              label="Oil"
              type="text"
              className="mr-4"
              pattern="[0-9]*"
              dataValueType="number"
              name="data.oil"
              onChange={onValueChange}
              disabled={isDeleted}
              value={model.data.oil}
            />
            <InputField
              label="Cycle time"
              type="text"
              pattern="[0-9]*"
              dataValueType="number"
              name="data.cycletime"
              onChange={onValueChange}
              disabled={isDeleted}
              value={model.data.cycletime}
            />
          </div>
          {client.config.salt && (
            <div className="flex flex-row justify-between mb-4">
              <InputField
                label="Salt"
                type="text"
                className="mr-4"
                pattern="[0-9]*"
                dataValueType="number"
                name="data.salt"
                onChange={onValueChange}
                disabled={isDeleted}
                value={model.data.salt}
              />
            </div>
          )}
        </div>

        <div className="flex flex-col flex-1 ml-4">
          <div className="flex justify-between">
            <h3 className="color-light text-md text-semibold mb-4">Aluminium</h3>
            <button disabled={isDeleted} onClick={onAddAluminium} className="button py-0 h-8 leading-8 border-light">
              + Add Aluminium
            </button>
          </div>
          <div className="flex flex-wrap justify-between">
            {model.data.aluminium.map((aluminium, i) => (
              <InputField
                key={i}
                type="text"
                pattern="[0-9]*"
                dataValueType="number"
                name={`data.aluminium[${i}]`}
                onChange={onValueChange}
                disabled={isDeleted}
                onRemove={() => {
                  onRemove('aluminium', i);
                }}
                value={aluminium}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <Link className="button mr-4" to="/cycles">
          {isSaved ? 'Back' : 'Cancel'}
        </Link>
        <button className="button button-primary" disabled={isDeleted} onClick={onSave}>
          {isSaved ? <IconCheck className="w-6 h-6 text-white" /> : 'Save changes'}
        </button>
      </div>
    </div>
  );
};

export default CycleEdit;
