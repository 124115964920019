import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconCheck } from '../components/icons/icons.js';

function MemberEdit({ memberId, member: initialMember, updateMember }) {
  const [isSaved, setIsSaved] = useState(false);
  const [errors, setErrors] = useState([]);
  const [member, setMember] = useState(initialMember);
  
  function setMemberVar(key) {
    return function (e) {
      setMember({
        ...member,
        [key]: e.target.value
      });
    };
  }

  function onUpdateMember() {
    const { name, email } = member;

    setErrors([]);

    updateMember({ name, email })
      .then(() => {
        setIsSaved(true);
      })
      .catch((ex) => {
        setErrors(ex.details || [err.message]);
      });
  }


  return (
    <div className="flex flex-col max-w-3xl p-4 mb-4">
      <h2 className="text-xl mb-4">Update user "{name}"</h2>
      <div className="mb-2">
        <label className="block text-dark" htmlFor="create-name">
          Full Name
        </label>
        <input
          className="input w-full"
          id="create-name"
          type="text"
          name="name"
          onChange={setMemberVar('name')}
          value={member.name}
        />
      </div>
      <div className="mb-2">
        <label className="block text-dark" htmlFor="create-email">
          Email
        </label>
        <input
          className="input w-full"
          id="create-email"
          type="text"
          name="email"
          onChange={setMemberVar('email')}
          value={member.email}
        />
      </div>
      <div className="mb-8">
        <label className="block text-dark" htmlFor="create-username">
          Username
        </label>
        <input
          className="input w-full"
          id="create-username"
          type="text"
          name="username"
          disabled={true}
          onChange={setMemberVar('username')}
          value={member.username}
        />
      </div>

      <div className="flex justify-end">
        <Link className="button mr-4" to="/members">
          {isSaved ? 'Back' : 'Cancel'}
        </Link>
        <button className="button button-primary" onClick={onUpdateMember}>
          {isSaved ? <IconCheck className="w-6 h-6 text-white" /> : 'Save'}
        </button>
      </div>

      {errors && (
        <ul>
          {errors.map((err) => (
            <li>{err}</li>
          ))}
        </ul>
      )}
    </div>
  );
}
MemberEdit.propTypes = {
  member: PropTypes.object,
  memberId: PropTypes.string,
  updateMember: PropTypes.func,
};

export default MemberEdit;
