import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { VIEWS } from './enums.js';

export default function TakeoutHeader(props) {
  const { view } = props;

  const templateBtnClass = classnames('block button w-48 text-center', {
    'border-primary text-primary': view !== VIEWS.CREATE,
    'bg-primary border-primary text-white': view === VIEWS.CREATE
  });
  return (
    <div className="bg-bglight flex px-12 pt-8 h-32">
      <div className="mb-4 self-end">
        <Link className={templateBtnClass} to="/exports/create">
          + New Template
        </Link>
      </div>
      <div className="flex-1"></div>
      <div className="mb-4 self-end">
        <Link to="/reports" className="button inline-block text-black mr-6">
          Reports
        </Link>
        <Link to="/exports" className="button button-primary border-white inline-block mr-6">
          Export
        </Link>
      </div>
    </div>
  );
}
