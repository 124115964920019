import React, { useState, useEffect, useRef } from 'react';

import YieldBox from './yield-box.js';
import { formatPercent } from '../../common/utils/units.js';
import { isEmpty } from '../../common/utils/obj.js';

function YieldBoxes({ clients, reportData }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const items = Object.values(reportData).reduce((sum, item) => {
      return [...sum, ...item];
    }, []);

    const pieCharts = [];

    clients.forEach((client) => {
      const clientData = items.filter((it) => it.clientCode === client.code);
      const useSalt = client.config.salt;

      // All client separated material types plus rest as dross
      const materialTypes = [
        ...client.config.materials.filter((mat) => mat.separate),
        {
          name: 'Dross',
          id: 'dross'
        }
      ];

      materialTypes.forEach((matType) => {
        const workData = clientData
          .filter((data) => data.material.toLowerCase() === matType.id.toLowerCase())
          .reduce(
            (sum, item) => {
              return {
                totalvolume: sum.totalvolume + item.totalvolume,
                volume: sum.volume + item.volume,
                aluminium: sum.aluminium + item.aluminium,
                residue: sum.residue + item.residue,
                oil: sum.oil + item.oil,
                oxygen: sum.oxygen + item.oxygen,
                salt: sum.salt + item.salt,
                lots: sum.lots + item.lots
              };
            },
            {
              totalvolume: 0,
              volume: 0,
              aluminium: 0,
              residue: 0,
              oil: 0,
              oxygen: 0,
              salt: 0,
              lots: 0
            }
          );

        const chartData = {
          clientName: client.name,
          clientId: client._id,
          useSalt: useSalt,
          materialType: matType.name,
          workData: workData,
          datasets: [
            {
              data: [workData.aluminium, workData.volume - workData.aluminium],
              backgroundColor: ['#fd5c04', '#080915'],
              label: `${client.name} - ${matType.name}`
            }
          ],
          labels: [
            `Aluminium ${formatPercent(workData.aluminium / workData.volume)}`,
            `Residue ${formatPercent((workData.volume - workData.aluminium) / workData.volume)}`
          ]
        };

        pieCharts.push(chartData);
      });
    });

    setChartData(pieCharts);
  }, [reportData]);

  if (isEmpty(chartData)) {
    return <div></div>;
  }

  return (
    <div className="flex flex-wrap gap-8">
      {chartData
        .sort((a, b) => {
          if (a.clientName === b.clientName) {
            return a.materialType > b.materialType ? 1 : -1;
          }
          return a.clientName > b.clientName ? -1 : 1;
        })
        .map((data) => (
          <YieldBox key={data.clientId + data.materialType} data={data} />
        ))}
    </div>
  );
}

export default YieldBoxes;
