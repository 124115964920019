const range = function(from = 0, to = 100) {
  const arr = [];
  let i = from;
  for (; i <= to; i++) {
    arr.push(i);
  }
  return arr;
};

export default range;
