import { useEffect, useState } from 'react';
import { baseApi } from '../common/config';
import { get } from '../common/utils/http';

function useDelivery(deliveryId){
  const [delivery, setDelivery] = useState(null);
  
  useEffect(() => {
    get(`${baseApi}/delivery/${deliveryId}`)
      .then((data) => {
        setDelivery(data);
      });
  }, [deliveryId]);

  return {
    delivery
  }
}

export default useDelivery;