import React from 'react';

export default function IconDelete({ className = '' } = {}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 24">
      <path
        fill="currentColor"
        d="M11.125,2H6.875a.3751.3751,0,0,0-.375.375V3h5V2.375A.3751.3751,0,0,0,11.125,2Zm0-2A2.3778,2.3778,0,0,1,13.5,2.375V3H17a1,1,0,0,1,0,2H1A1,1,0,0,1,1,3H4.5V2.375A2.3778,2.3778,0,0,1,6.875,0Zm4.3281,9H2.5471L4.2426,21.0543l.002.0146A1.0688,1.0688,0,0,0,5.3066,22h7.3865a1.0689,1.0689,0,0,0,1.0625-.931ZM15.81,7a1.688,1.688,0,0,1,1.677,1.8976l-1.7485,12.435A3.0687,3.0687,0,0,1,12.6931,24H5.3066a3.072,3.072,0,0,1-3.0455-2.6739L.5151,8.9118A1.6885,1.6885,0,0,1,2.19,7Z"
      />
    </svg>
  );
}
