export const INTERVALS = {
  lots: 'Lots',
  months: 'Months'
};

export const INTERVALS_KEYS = Object.keys(INTERVALS);

export const FIELDTYPES = {
  date: 'Date',
  dateformated: 'Date formated',
  lotnumber: 'Lotnumber',
  volume: 'Volume separated',
  volumecombined: 'Volume combined',
  containers: 'Containers separated',
  material: 'Material',
  oxygen: 'Oxygen',
  oil: 'Oil',
  salt: 'Salt',
  cycletime: 'Cycle time',
  aluminium: 'Aluminium separated',
  aluminiumtotal: 'Aluminium combined',
  yield: 'Yield',
  notes: 'Notes',
  state: 'State'
};

export const FIELDTYPES_KEYS = Object.keys(FIELDTYPES);

export const VIEWS = {
  TEMPLATES: 'TEMPLATES',
  CREATE: 'CREATE',
  EDIT: 'EDIT'
};
