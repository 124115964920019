import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, put } from '../common/utils/http.js';
import { baseApi } from '../common/config.js';

import InputField from '../components/fields/input.js';

function Company() {
  const [company, setCompany] = useState({});
  const [ready, setReady] = useState(false);
  const [msg, setMsg] = useState('');

  function onValueChange(e) {
    setCompany({
      ...company,
      [e.target.name]: e.target.value,
    });
  }

  function onUpdateCompany(e) {
    const { name, contact, email } = company;

    put(`${baseApi}/company/`, { name, contact, email })
      .then((data) => {
        setMsg('Company updated');

        setTimeout(() => {
          setMsg('');
        }, 3000);
      })
      .catch((ex) => {
        console.log(ex);
        setMsg(ex.message);
      });
  }

  useEffect(() => {
    get(`${baseApi}/company/`).then((data) => {
      setCompany(data);
      setReady(true);
    });
  }, []);

  if (!ready) {
    return <p>Loading...</p>;
  }

  return (
    <div className="company-settings max-w-lg mb-8">
      <h2 className="text-2xl mb-4">{company.name}</h2>
      {msg && <p className="text-md mb-4">{msg}</p>}
      <InputField label="Company Name" name="name" onChange={onValueChange} value={company.name} />
      <InputField
        label="Contact person"
        name="contact"
        onChange={onValueChange}
        value={company.contact}
      />
      <InputField
        label="Contact email"
        name="email"
        type="email"
        onChange={onValueChange}
        value={company.email}
      />
      <div>
        <button onClick={onUpdateCompany} className="button">
          Update
        </button>
      </div>
    </div>
  );
}
Company.propTypes = {
  user: PropTypes.object
};

export default Company;
