import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MultiDatePicker from '../components/multi-date-picker.js';
import { INTERVALS, FIELDTYPES } from './enums.js';
import { getTomorrow, getStartOfMonth, getPrettyDate, getPrettierDate } from '../common/utils/date.js';

export default function TakeoutItem(props) {
  const { template, client, downloadExport } = props;
  const [from, setFrom] = useState(getStartOfMonth());
  const [to, setTo] = useState(getTomorrow());

  function onDownloadExport() {
    downloadExport({
      id: template._id,
      from,
      to
    });
  }

  return (
    <div
      className="w-full max-w-2xl border border-light rounded-xl p-2 bg-bgdark flex flex-col mb-6 mr-6"
      key={template._id}
    >
      <div className="flex justify-between">
        <h2 className="text-2xl pb-2 text-white">{template.name}</h2>
        <div>
          <div className="relative inline-block text-primary mr-2">
            From {getPrettyDate(from)} - to {getPrettyDate(to)}
          </div>
          <div className="relative inline-block mr-4">
            <MultiDatePicker className="" from={from} setFrom={setFrom} to={to} setTo={setTo} xpos="right" />
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-row rounded-lg overflow-hidden bg-whiteish">
        <div className="flex-1 flex flex-col p-4">
          <h2 className="text-xl text-primary my-4">{client.name}</h2>
          <h3 className="text-xl mb-4">{INTERVALS[template.intervals]}</h3>
          <p className="flex-1">{template.description}</p>
          <div>
            <Link className="button inline-block" to={`/exports/${template._id}`}>
              Edit Template
            </Link>
          </div>
        </div>
        <div className="flex-1">
          <table className="table-auto w-full text-left table-oddlines">
            <tbody>
              {template.fields.map((field) => (
                <tr key={field} className="h-12">
                  <td className="text-right px-2 font-semi">{FIELDTYPES[field]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="text-center pt-4">
        <button onClick={onDownloadExport} className="button border-primary text-primary bg-transparent">
          Download Export
        </button>
      </div>
    </div>
  );
}
