import { prettyNumber } from './numbers.js';

export function formatWeight(inp) {
  if (inp > 10000) {
    return `${(inp / 1000).toFixed(1)} t`;
  }
  return `${prettyNumber(inp)} kg`;
}

export function formatPercent(inp) {
  if (isNaN(inp)) {
    return '0 %';
  }
  return `${(inp * 100).toFixed(1)} %`;
}
