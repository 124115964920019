import React from 'react';

export default function IconLock({ className = '' } = {}) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <path
        fill="currentColor"
        d="M426.234,213.4739a21.5141,21.5141,0,0,0,16.4651-30.8763c-.0772.001-11.1566.1242-15.0969,7.5211a13.9155,13.9155,0,0,1-8.5825,6.9168,12.0778,12.0778,0,0,1-4.7085.3471c-2.6728-.2963-8.2423-.4889-10.0414,2.7065a4.002,4.002,0,0,0-.387,1.0188,21.5075,21.5075,0,0,0,22.3512,12.366"
      />
      <path
        fill="currentColor"
        d="M14.1285,6.708V5.128a5.1285,5.1285,0,0,0-10.257,0v1.58a2.5035,2.5035,0,0,0-1.543,2.309V15.5a2.5028,2.5028,0,0,0,2.5,2.5h8.343a2.5028,2.5028,0,0,0,2.5-2.5V9.017A2.5035,2.5035,0,0,0,14.1285,6.708ZM9.0005,1a4.1329,4.1329,0,0,1,4.128,4.128V6.517H4.8715V5.128A4.1331,4.1331,0,0,1,9.0005,1Zm5.671,14.5a1.5018,1.5018,0,0,1-1.5,1.5H4.8285a1.5018,1.5018,0,0,1-1.5-1.5V9.017a1.5012,1.5012,0,0,1,1.5-1.5h8.343a1.5012,1.5012,0,0,1,1.5,1.5Z"
      />
      <path
        fill="currentColor"
        d="M9.381,11.1855a.6388.6388,0,0,0-.18-.061.9563.9563,0,0,0-.58.061.92.92,0,0,0-.33.21,1.0477,1.0477,0,0,0-.29.71.9857.9857,0,0,0,.07.38,1.2046,1.2046,0,0,0,.22.33,1.0141,1.0141,0,0,0,1.42,0,1.1686,1.1686,0,0,0,.21-.33.9954.9954,0,0,0,.08-.38,1.0477,1.0477,0,0,0-.29-.71,1.0155,1.0155,0,0,0-.33-.21"
      />
    </svg>
  );
}
