import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { get, post, put, del } from '../common/utils/http.js';
import { baseApi } from '../common/config.js';
import { INTERVALS, INTERVALS_KEYS, FIELDTYPES, FIELDTYPES_KEYS, VIEWS } from './enums.js';
import Confirmation from '../components/confirmation.js';
import { IconGarbage, IconCheck } from '../components/icons/icons.js';
import TakeoutHeader from './takeout-header.js';
import { clientsState } from '../state/clients.js';

const initialTemplate = {
  _id: null,
  clientId: '',
  name: '',
  description: '',
  intervals: 'lots',
  separateMaterial: false,
  fields: []
};

function TemplateCreate(props) {
  const clients = useRecoilValue(clientsState);
  const navigate = useNavigate();
  const { id } = useParams()
  const [view, setView] = useState(VIEWS.CREATE);
  const [addfield, setAddfield] = useState('');
  const [isCreated, setIsCreated] = useState(false);
  const [shouldDelete, setShouldDelete] = useState(false);
  const [template, setTemplate] = useState({ ...initialTemplate });

  useEffect(() => {
    if (id) {
      get(`${baseApi}/export/${id}`).then((data) => {
        setTemplate(data);
        setView(VIEWS.EDIT);
      });
    } else {
      setTemplate({ ...initialTemplate });
    }
  }, [id]);

  function onCreateTemplate() {
    // If template already exists
    if (template._id) {
      put(`${baseApi}/export/${template._id}`, {
        clientId: template.clientId,
        name: template.name,
        description: template.description,
        intervals: template.intervals,
        separateMaterial: template.separateMaterial,
        fields: template.fields
      }).then((d) => {
        setTemplate(d);
        setIsCreated(true);
      });
    } else {
      post(`${baseApi}/export`, {
        clientId: template.clientId,
        name: template.name,
        description: template.description,
        intervals: template.intervals,
        separateMaterial: template.separateMaterial,
        fields: template.fields
      }).then((d) => {
        setTemplate(d);
        setIsCreated(true);
      });
    }
  }

  function onDeleteTemplate() {
    del(`${baseApi}/export/${template._id}`).then((d) => {
      navigate(`/exports/`);
    });
  }

  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setTemplate({
      ...template,
      [name]: value
    });
  }

  function addSelectedField() {
    if (addfield === '') {
      return;
    }
    setTemplate({
      ...template,
      fields: [...template.fields, addfield]
    });

    setAddfield('');
  }

  function removeField(key) {
    setTemplate({
      ...template,
      fields: template.fields.filter((f) => f !== key)
    });
  }

  return (
    <div className="flex-1 overflow-y-auto">
      <TakeoutHeader view={VIEWS.CREATE} />
      <div className="template-create max-w-6xl p-12">
        <div className="w-full max-w-3xl border border-light rounded-xl p-2 bg-bgdark flex flex-col">
          <div className="flex flex-col p-2">
            <label className="text-lg pb-2 text-white" htmlFor="create-name">
              Template name *
            </label>
            <input
              className="shadow appearance-none border border-red-500 rounded w-64 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="create-name"
              type="text"
              name="name"
              onChange={handleInputChange}
              value={template.name}
            />
          </div>
          <div className="flex-1 rounded-lg bg-whiteish">
            <div className="flex flex-row">
              <div className="flex-50 p-4">
                <div className="mb-6">
                  <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="create-clientId">
                    Client *
                  </label>
                  <select
                    className="shadow appearance-none border border-red-500 bg-white rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="create-clientId"
                    name="clientId"
                    onChange={handleInputChange}
                    value={template.clientId}
                  >
                    <option value="">Select one</option>
                    {clients.map((client) => (
                      <option key={client._id} value={client._id}>
                        {client.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="create-intervals">
                    Interval *
                  </label>
                  <select
                    className="shadow appearance-none border border-red-500 bg-white rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="create-intervals"
                    name="intervals"
                    onChange={handleInputChange}
                    value={template.intervals}
                  >
                    {INTERVALS_KEYS.map((iv) => (
                      <option key={iv} value={iv}>
                        {INTERVALS[iv]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="create-description">
                    Description *
                  </label>
                  <textarea
                    className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="create-description"
                    type="text"
                    name="description"
                    onChange={handleInputChange}
                    value={template.description}
                  ></textarea>
                </div>

                <div className="mb-6">
                  <input
                    id="create-separate-material"
                    type="checkbox"
                    name="separateMaterial"
                    checked={template.separateMaterial}
                    onChange={handleInputChange}
                  />
                  <label
                    htmlFor="create-separate-material"
                    className="pl-6 text-gray-700 text-lg font-bold mb-2"
                    title="Separate special material from rest"
                  >
                    Separate materials
                  </label>
                </div>
              </div>
              <div className="flex flex-col flex-50 p-4">
                <div className="mb-6">
                  <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="create-addfield">
                    Fields
                  </label>
                  {template.fields.map((key) => {
                    return (
                      <div
                        key={key}
                        className="border border-light mb-2 rounded bg-white flex justify-between py-1 px-2"
                      >
                        {FIELDTYPES[key]}
                        <button className="border border-dark w-8" onClick={() => removeField(key)}>
                          -
                        </button>
                      </div>
                    );
                  })}
                  <select
                    className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 bg-white text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="create-addfield"
                    name="addfield"
                    onChange={(e) => {
                      setAddfield(e.target.value);
                    }}
                    value={addfield}
                  >
                    <option value="">Select one</option>
                    {FIELDTYPES_KEYS.filter((key) => !template.fields.includes(key)).map((key) => (
                      <option key={key} value={key}>
                        {FIELDTYPES[key]}
                      </option>
                    ))}
                  </select>
                  <button className="button" onClick={addSelectedField}>
                    Add field
                  </button>
                </div>
              </div>
            </div>
            <div className="relative flex justify-end p-4">
              <button className="button button-danger" onClick={() => setShouldDelete(true)}>
                <IconGarbage className="w-6 h-6 inline-block" /> Delete
              </button>
              {shouldDelete && (
                <Confirmation
                  title={`Delete template ${template.name}`}
                  text="Are you sure you want to permanently delete this template?"
                  onAccept={onDeleteTemplate}
                  onCancel={() => setShouldDelete(false)}
                />
              )}
              <span className="flex-1"></span>
              <Link className="button mr-4" to="/exports">
                {isCreated ? 'Back' : 'Cancel'}
              </Link>
              <button className="button button-primary" onClick={onCreateTemplate}>
                {isCreated ? <IconCheck className="w-6 h-6 text-white" /> : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateCreate;
