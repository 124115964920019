import React, { useEffect, useState } from 'react';
import { baseApi } from '../common/config';
import { get, put } from '../common/utils/http';

function CompanyLotnumber() {
  const [lotNumber, setLotnumber] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    get(`${baseApi}/company/lotnumber`)
      .then((data) => {
        setLotnumber(data.lotnumber);
      });
  }, []);
  
  function onChange(e) {
    const value = Number(e.target.value);

    if (e.target.value === '') {
      setLotnumber('');
    }
    if (!isNaN(value)) {
      setLotnumber(value);
    }
  }

  function onSaveLotnumber() {
    if (lotNumber > 0 && lotNumber < 1000) {
      put(`${baseApi}/company/lotnumber`, { lotnumber: lotNumber })
        .then(() => {
          setMessage('Lotnumber changed successfully');
      })
      .catch((ex) => {
        setError(ex.message);
      });
    } else {
      setMessage('Lotnumber must be between 1 and 999');
    }

    setTimeout(() => {
      setMessage('');
      setError('');
    }, 3000);
  }

  return (
    <div className="user-create max-w-lg mb-8">
      <h2 className="text-xl text-dark mb-4">Change current lotnumber</h2>
      {error && <p>{error}</p>}
      {message && <p>{message}</p>}
      <div className="mb-4">
        <label className="block text-dark mb-2" htmlFor="create-lotnumber">
          Current Lotnumber
        </label>
        <input
          className="input"
          id="create-lotnumber"
          type="number"
          name="lotnumber"
          onChange={onChange}
          value={lotNumber}
        />
        <p className="text-xs leading-1">
          Set the current lotnumber.
          <br />
          The next started cycle will get the next number after.
        </p>
      </div>
      <div className="flex items-center justify-between">
        <button className="button" type="button" onClick={onSaveLotnumber}>
          Update Lotnumber
        </button>
      </div>
    </div>
  );
}

export default CompanyLotnumber;
